import { createSlice } from '@reduxjs/toolkit';
import {
  getUsersFunction,
  addUserFunction,
  updateUserFunction,
  updateUserPasswordFunction,
  updateUserStatusFunction,
  deleteUserFunction,
  findUsersByCompanyIdFunction,
  findUsersByProjectIdFunction,
  addGroupToUserFunction,
  fetchGroupOfUserFunction,
  searchUsersFunction,
  updateUserAvatarFunction,
  findByIdUsersFunction,
  deleteGroupOfUserFunction,
} from 'api/users';

const initialState = {
  loading: {
    getUsers: false,
    addUser: false,
    updateUser: false,
    updateUserPassword: false,
    updateUserStatus: false,
    deleteUser: false,
    addGroupToUser: false,
    deleteGroupOfUser: false,
    fetchGroupOfUser: false,
    searchUsers: false,
    updateUserAvatar: false,
    findUser: false,
  },
  hasErrors: {
    getUsers: false,
    addUser: false,
    updateUser: false,
    updateUserPassword: false,
    updateUserStatus: false,
    deleteUser: false,
    addGroupToUser: false,
    deleteGroupOfUser: false,
    fetchGroupOfUser: false,
    searchUsers: false,
    updateUserAvatar: false,
    findUser: false,
  },
  users: [],
  userGroups: [],
  user: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    addUserSucces: (state, { payload }) => {
      state.users.push(payload);
      state.loading.addUser = false;
      state.hasErrors.addUser = false;
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading.getUsers = false;
      state.hasErrors.getUsers = false;
    },
    searchUsersSuccess: (state, { payload }) => {
      const { users, company_id } = payload;

      state.users = !company_id
        ? users
        : users.filter((user) => user.company_id === company_id);
      state.loading.searchUsers = false;
      state.hasErrors.searchUsers = false;
    },
    findUsersByIdSuccess: (state, { payload }) => {
      state.user = payload;
      state.loading.findUser = false;
      state.hasErrors.findUser = false;
    },
    findUsersByCIdSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading.getUsers = false;
      state.hasErrors.getUsers = false;
    },
    addUserSuccess: (state, { payload }) => {
      state.users.unshift(payload);
      state.loading.addUser = false;
      state.hasErrors.addUser = false;
    },
    updateUserSuccess: (state, { payload }) => {
      state.loading.updateUser = false;
      state.hasErrors.updateUser = false;
    },
    updateUserPasswordSuccess: (state, { payload }) => {
      state.loading.updateUserPassword = false;
      state.hasErrors.updateUserPassword = false;
    },
    updateUserStatusSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading.updateUserStatus = false;
      state.hasErrors.updateUserStatus = false;
    },
    deleteUserSuccess: (state, { payload }) => {
      state.users.filter((user) => user.id !== payload);
      state.loading.deleteUser = false;
      state.hasErrors.deleteUser = false;
    },
    fetchGroupOfUserSuccess: (state, { payload }) => {
      state.userGroups = payload;
      state.loading.fetchGroupOfUser = false;
      state.hasErrors.fetchGroupOfUser = false;
    },
    addGroupToUserSuccess: (state, { payload }) => {
      state.loading.addGroupToUser = false;
      state.hasErrors.addGroupToUser = false;
    },
    updateUserAvatarSuccess: (state, { payload }) => {
      state.users = state.users.map((user) => {
        if (user.id === payload.user_id) {
          return { ...user, avatar: payload.avatar };
        }
        return user;
      });
      state.loading.updateUserAvatar = false;
      state.hasErrors.updateUserAvatar = false;
    },
    deleteGroupOfUserSuccess: (state, { payload }) => {
      state.loading.deleteGroupOfUser = false;
      state.hasErrors.deleteGroupOfUser = false;
    },
  },
});

export const {
  asyncStart,
  asyncFailure,
  addUserSuccess,
  updateUserSuccess,
  updateUserPasswordSuccess,
  updateUserStatusSuccess,
  deleteUserSuccess,
  getUsersSuccess,
  findUsersByCIdSuccess,
  addGroupToUserSuccess,
  fetchGroupOfUserSuccess,
  searchUsersSuccess,
  updateUserAvatarSuccess,
  findUsersByIdSuccess,
  deleteGroupOfUserSuccess,
} = usersSlice.actions;

export default usersSlice.reducer;

export const usersSelector = (state) => state.users;

export const fetchUsers = () => async (dispatch) => {
  dispatch(asyncStart('getUsers'));

  try {
    const _users = await getUsersFunction();
    dispatch(getUsersSuccess(_users));
  } catch (error) {
    dispatch(asyncFailure('getUsers'));
  }
};

export const searchUsers = (search, company_id) => async (dispatch) => {
  dispatch(asyncStart('searchUsers'));

  try {
    const users = await searchUsersFunction(search);
    dispatch(searchUsersSuccess({ users, company_id }));
  } catch (error) {
    dispatch(asyncFailure('searchUsers'));
  }
};

export const fetchUsersById = (user_id) => async (dispatch) => {
  dispatch(asyncStart('findUser'));

  try {
    const user = await findByIdUsersFunction(user_id);
    dispatch(findUsersByIdSuccess(user));
  } catch (error) {
    dispatch(asyncFailure('findUser'));
  }
};

export const fetchUsersByCompanyId = (companyId) => async (dispatch) => {
  dispatch(asyncStart('getUsers'));

  try {
    const users = await findUsersByCompanyIdFunction(companyId);
    dispatch(findUsersByCIdSuccess(users));
  } catch (error) {
    dispatch(asyncFailure('getUsers'));
  }
};

export const fetchUsersByProjectId = (projectId) => async (dispatch) => {
  dispatch(asyncStart('getUsers'));

  try {
    const users = await findUsersByProjectIdFunction(projectId);
    dispatch(findUsersByCIdSuccess(users));
  } catch (error) {
    dispatch(asyncFailure('getUsers'));
  }
};

export const addUser = (userObj) => async (dispatch) => {
  dispatch(asyncStart('addUser'));
  try {
    const user = await addUserFunction(userObj);
    dispatch(addUserSuccess(user));
    return user;
  } catch (error) {
    dispatch(asyncFailure('addUser'));
    throw error;
  }
};

export const updateUser = (userObj) => async (dispatch) => {
  dispatch(asyncStart('updateUser'));
  try {
    await updateUserFunction(userObj);
    dispatch(updateUserSuccess(userObj));
  } catch (error) {
    dispatch(asyncFailure('updateUser'));
  }
};

export const updateUserPassword = (userObj) => async (dispatch) => {
  dispatch(asyncStart('updateUserPassword'));
  try {
    await updateUserPasswordFunction(userObj);
    dispatch(updateUserPasswordSuccess(userObj));
  } catch (error) {
    dispatch(asyncFailure('updateUserPassword'));
  }
};

export const updateUserStatus = (userObj) => async (dispatch, state) => {
  dispatch(asyncStart('updateUserStatus'));
  try {
    await updateUserStatusFunction(userObj);
    const tempUsers = state().users.users.map((user) => {
      if (user.id === userObj.user_id) {
        return { ...user, status: userObj.status === '1' ? 1 : 0 };
      }
      return user;
    });
    dispatch(updateUserStatusSuccess(tempUsers));
  } catch (error) {
    dispatch(asyncFailure('updateUserStatus'));
  }
};

export const deleteUser = (user_id) => async (dispatch) => {
  dispatch(asyncStart('deleteUser'));
  try {
    await deleteUserFunction(user_id);
    dispatch(deleteUserSuccess(user_id));
  } catch (error) {
    dispatch(asyncFailure('deleteUser'));
  }
};

export const fetchGroupOfUser = (userId) => async (dispatch) => {
  dispatch(asyncStart('fetchGroupOfUser'));
  try {
    const userGroups = await fetchGroupOfUserFunction(userId);
    dispatch(fetchGroupOfUserSuccess(userGroups));
  } catch (error) {
    dispatch(asyncFailure('fetchGroupOfUser'));
  }
};

export const addGroupToUser = (userId, groupId) => async (dispatch) => {
  dispatch(asyncStart('addGroupToUser'));
  try {
    await addGroupToUserFunction(userId, groupId);
    dispatch(addGroupToUserSuccess());
  } catch (error) {
    dispatch(asyncFailure('addGroupToUser'));
  }
};

export const updateUserAvatar = (user_id, avatar) => async (dispatch) => {
  dispatch(asyncStart('updateUserAvatar'));
  try {
    const response = await updateUserAvatarFunction(user_id, avatar);
    dispatch(updateUserAvatarSuccess({ user_id, avatar }));
  } catch (error) {
    dispatch(asyncFailure('updateUserAvatar'));
  }
};

export const deleteGroupOfUser = (userId, groupId) => async (dispatch) => {
  dispatch(asyncStart('deleteGroupOfUser'));
  try {
    await deleteGroupOfUserFunction(userId, groupId);
    dispatch(deleteGroupOfUserSuccess());
  } catch (error) {
    dispatch(asyncFailure('deleteGroupOfUser'));
  }
};

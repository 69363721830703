import {
  getInboxMailsByProjecFunction,
  getSendboxMailsByProjecFunction,
  insertMailFunction,
} from 'api';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  loading: { getInbox: false, getSendbox: false, insertMail: false },
  hasErrors: { getInbox: false, getSendbox: false, insertMail: false },
  sendBox: [],
  inBox: [],
};

const mailsSlice = createSlice({
  name: 'mails',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getInboxSuccess: (state, { payload }) => {
      state.inBox = payload;
      state.loading.getInbox = false;
      state.hasErrors.getInbox = false;
    },
    getSendboxSuccess: (state, { payload }) => {
      state.sendBox = payload;
      state.loading.getSendbox = false;
      state.hasErrors.getSendbox = false;
    },
    insertMailSuccess: (state, { payload }) => {
      state.sendBox.push(payload);
      state.loading.insertMail = false;
      state.hasErrors.insertMail = false;
    },
  },
});

export const {
  asyncStart,
  asyncFailure,
  getInboxSuccess,
  getSendboxSuccess,
  insertMailSuccess,
} = mailsSlice.actions;

export default mailsSlice.reducer;

export const mailsSelector = (state) => state.mails;

export const getInboxMails = (project_id, user_id) => async (dispatch) => {
  dispatch(asyncStart('getInbox'));

  try {
    const response = await getInboxMailsByProjecFunction(project_id, user_id);
    dispatch(getInboxSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getInbox'));
  }
};

export const getSendboxMails = (project_id, user_id) => async (dispatch) => {
  dispatch(asyncStart('getSendbox'));

  try {
    const response = await getSendboxMailsByProjecFunction(project_id, user_id);
    dispatch(getSendboxSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getSendbox'));
  }
};

export const insertMail = ({
  projectId,
  formId,
  formNo,
  formName,
  fromId,
  fromName,
  toId,
  toName,
  toJSON,
  ccJSON,
  description,
  fromCompanyId,
  fromCompanyName,
  toCompanyId,
  toCompanyName,
}) => async (dispatch) => {
  dispatch(asyncStart('insertMail'));

  try {
    const response = await insertMailFunction({
      projectId,
      formId,
      formNo,
      formName,
      fromId,
      fromName,
      toId,
      toName,
      toJSON,
      ccJSON,
      description,
      fromCompanyId,
      fromCompanyName,
      toCompanyId,
      toCompanyName,
    });
    dispatch(insertMailSuccess(response));
    return response;
  } catch (error) {
    dispatch(asyncFailure('insertMail'));
  }
};

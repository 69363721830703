import APIHelper from '../helpers/APIHelper';

export async function getCompaniesFunction() {
  const response = await APIHelper().get('companies/list');
  if (response.error) {
    throw new Error(response.error);
  }
  return response.companies;
}

export async function searchCompaniesFunction(search) {
  const response = await APIHelper().post('companies/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.companies;
}

export async function findByIdCompaniessFunction(company_id) {
  const response = await APIHelper().post('companies/find', null, {
    company_id
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.company;
}

export async function addCompanyFunction(companyObj) {
  const response = await APIHelper().post('companies/insert', null, {
    ...companyObj
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.company;
}

export async function updateCompanyFunction(companyObj) {
  const response = await APIHelper().post('companies/update', null, {
    ...companyObj
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.status;
}

export async function deleteCompanyFunction(company_id) {
  const response = await APIHelper().post('companies/delete', null, {
    company_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
}

export async function addProjectToCompanyFunction(company_id, project_id) {
  const response = await APIHelper().post('companies/add_projects', null, {
    company_id,
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function deleteProjectToCompanyFunction(company_id, project_id) {
  const response = await APIHelper().post('companies/delete_project', null, {
    company_id,
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.status;
}

export async function updateCompanyLogoFunction(companyObj) {
  const response = await APIHelper().post('companies/updatelogo', null, {
    ...companyObj
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.status;
}

export async function findProjectToCompanyFunction(company_id) {
  const response = await APIHelper().post('companies/find_projects', null, {
    company_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.company_projects;
}

export async function findCompaniesByProjectIdFunction(project_id) {
  const response = await APIHelper().post('companies/find_companies', null, {
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }

  return response.data.company_projects;
}

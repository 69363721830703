import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import CommonContainer from 'components/Common/CommonContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Avatar,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { getRandomColor } from 'helpers';

import {
  MdRemoveRedEye, MdCreate, MdLock, MdLockOpen,
} from 'react-icons/md';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  usersSelector,
  fetchUsersByCompanyId,
  addUser,
  addGroupToUser,
  deleteGroupOfUser,
  groupsSelector,
  fetchGroupsByCompanyId,
  fetchGroupOfUser,
  searchUsers,
  updateUser,
  updateUserPassword,
  updateUserStatus,
} from '../../slices';
import UserForm from './UserForm';

import { authentication } from '../../helpers/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24),
    },
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '5px 15px',
  },
  table: {
    minWidth: 650,
  },
  userAvatar: {
    background: getRandomColor(),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

const UserAvatar = ({ name, img }) => {
  const classes = useStyles();
  if (!img) {
    const fullName = name.split(' ');
    const avatar = fullName.length === 0
      ? ' '
      : fullName.length === 1
        ? (fullName[0][0]?.toUpperCase() ?? '')
        : (fullName[0][0]?.toUpperCase() ?? '') + (fullName[1][0]?.toUpperCase() ?? '');

    return <Avatar className={classes.userAvatar}>{avatar}</Avatar>;
  }
  return <Avatar alt={name} src={img} />;
};

const Role = styled.div`
  margin: auto;
  color: #fff;
  background: ${(props) => {
    if (props.role === '1') {
      return 'linear-gradient(159.27deg, #F24343 0%, #FA6400 100%)';
    }
    if (props.role === '2') {
      return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
    }
    if (props.role === '3') {
      return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
    }
    if (props.role === '4') {
      return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
    }
    if (props.role === '5') {
      return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
    }
    return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
  }};
  font-size: 12px;
  font-weight: bold;
  width: auto;
  max-width: 200px;
  padding: 8px 4px;
  box-shadow: 0px 1px 4px rgba(188, 188, 188, 0.5);
  border-radius: 5px;
`;

const Status = styled.div`
  color: ${(props) => (props.active ? '#6DD400' : '#E02020')};
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

export default ({ company }) => {
  const classes = useStyles();

  const history = useHistory();

  const intl = useIntl();

  const { role: userRole } = authentication.userValue;

  const defaultUserName = {
    name: '',
    email: '',
    title: '',
    role: null,
    groups: [],
  };
  const [changed, setChanged] = useState(false);
  const [userName, setUserName] = useState(defaultUserName);
  const [userGroupsList, setUserGroupsList] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchText, setSearchText] = useState('');
  const handleSearch = useCallback(
    (value) => {
      setSearchText(value);
      dispatch(searchUsers(value, company.id));
    },
    [searchText],
  );

  const dispatch = useDispatch();
  const { users, userGroups, loading } = useSelector(usersSelector);
  const { groups } = useSelector(groupsSelector);

  useEffect(() => {
    if (!searchText) {
      dispatch(fetchUsersByCompanyId(company.id));
    }
    dispatch(fetchGroupsByCompanyId(company.id));
  }, [dispatch]);

  useEffect(() => {}, [users]);

  useEffect(() => {
    if (userGroupsList !== userGroups) {
      setUserGroupsList(userGroups);
    }
  }, [userGroups]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActions = (user) => (
    <>
      <IconButton>
        <MdRemoveRedEye
          size={16}
          onClick={() => {
            history.push(`/users/${user.id}`);
          }}
        />
      </IconButton>
      <IconButton
        onClick={() => {
          setChanged(false);
          dispatch(fetchGroupOfUser(user.id));
          setUserName(user);
          setShowEditModal(true);
        }}
      >
        <MdCreate size={16} />
      </IconButton>
      <IconButton
        onClick={() => {
          dispatch(
            updateUserStatus({
              user_id: user.id,
              status: user.status ? '0' : '1',
            }),
          );
        }}
      >
        {user.status ? <MdLockOpen size={16} /> : <MdLock size={16} />}
      </IconButton>
    </>
  );
  const authorityList = [
    { id: '4', name: intl.formatMessage({ id: 'MANAGER' }) },
    { id: '3', name: intl.formatMessage({ id: 'FORM_MANAGER' }) },
    { id: '2', name: intl.formatMessage({ id: 'FORM_USER' }) },
    { id: '1', name: intl.formatMessage({ id: 'VIEWER' }) },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_NAME_AND_SURNAME' }),
    ),
    email: Yup.string().required(intl.formatMessage({ id: 'REQUIRED_MAIL' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED_PASSWORD' }))
      .min(8, intl.formatMessage({ id: 'PASSWORD_IS_TOO_SHORT' }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        intl.formatMessage({ id: 'PASSWORD_MUST_CONTAIN' }),
      ),
    passwordConfirmation: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED_PASSWORD' }))
      .oneOf(
        [Yup.ref('password')],
        intl.formatMessage({ id: 'REQUIRED_PASSWORD_HAVE_TO_MATCH' }),
      ),
    role: Yup.string().required(intl.formatMessage({ id: 'REQUIRED_ROLE' })),
  });

  const initialValues = {
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    title: '',
    role: null,
    groups: [],
  };

  return (
    <>
      <CommonContainer
        containerName={intl.formatMessage({ id: 'USERS' })}
        addButton={intl.formatMessage({ id: 'NEW_USER' })}
        setShowModal={() => {
          if (userRole === '4' || userRole === '5') {
            setChanged(false);
            setUserName(defaultUserName);
            setUserGroupsList([]);
            setShowModal(true);
          }
        }}
        page="users"
        inside="true"
        search="true"
        handleSearch={handleSearch}
      >
        <TableContainerStyled component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCellStyled align="center" width="60px">
                  #
                </TableCellStyled>
                <TableCellStyled>
                  <FormattedMessage id="USER_NAME" />
                </TableCellStyled>
                <TableCellStyled align="center" width="200px">
                  <FormattedMessage id="ROLE_IN_COMPANY" />
                </TableCellStyled>
                <TableCellStyled align="center" width="100px">
                  <FormattedMessage id="STATE" />
                </TableCellStyled>
                <TableCellStyled align="center" width="100px">
                  <FormattedMessage id="ACTIONS" />
                </TableCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  const isActive = user.status === 1;
                  const status = isActive
                    ? intl.formatMessage({ id: 'ACTIVE' })
                    : intl.formatMessage({ id: 'PASSIVE' });

                  const role = authorityList.find((el) => el.id === user.role)
                    .name;

                  return (
                    <TableRow key={`${user.id}-${user.name}`}>
                      <TableCellStyled align="center" id="true" width="60px">
                        <UserAvatar name={user.name} />
                      </TableCellStyled>
                      <TableCellStyled>{user.name}</TableCellStyled>
                      <TableCellStyled align="center" width="200px">
                        <Role role={user.role}>{role}</Role>
                      </TableCellStyled>
                      <TableCellStyled align="center" width="100px">
                        <Status active={isActive}>{status}</Status>
                      </TableCellStyled>
                      <TableCellStyled align="center" width="100px">
                        <Actions>{getActions(user)}</Actions>
                      </TableCellStyled>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerStyled>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CommonContainer>

      <Dialog
        open={showModal}
        onClose={() => {
          // dispatch(fetchUsersByCompanyId(company.id));
          setShowModal(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitle}>
            <FormattedMessage id="ADD_NEW_USER" />
            <IconButton
              onClick={() => {
                // dispatch(fetchUsersByCompanyId(company.id));
                setShowModal(false);
              }}
            >
              <AiOutlineCloseCircle />
            </IconButton>
          </div>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            const userObj = {
              name: values.name,
              email: values.email,
              password: values.password,
              title: values.title,
              role: values.role.id,
              company_id: company.id,
              status: '1',
            };

            dispatch(addUser(userObj))
              .then((res) => {
                const allPromise = Promise.all(
                  values.groups.map((group) => {
                    dispatch(addGroupToUser(res.id, group.id));
                  }),
                );
                allPromise.then(() => {
                  // dispatch(fetchUsersByCompanyId(company.id));
                  setShowModal(false);
                });
              })
              .catch((err) => {
                if (err?.message === 'Email Kullanımda') {
                  setErrors({
                    email: intl.formatMessage({
                      id: 'EXIST_EMAIL',
                    }),
                  });
                } else {
                  alert(
                    intl.formatMessage({
                      id: 'UNKNOWN_ERROR',
                    }),
                  );
                }
              });
          }}
        >
          {({
            values,
            handleChange,
            handleReset,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      {' '}
                      <FormattedMessage id="NAME_SURNAME" />{' '}
                    </p>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_NAME_SURNAME',
                      })}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="MAIL" />
                    </p>
                    <TextField
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'ENTER_MAIL' })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="PASSWORD" />{' '}
                    </p>
                    <TextField
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="CONFIRM_PASSWORD" />
                    </p>
                    <TextField
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_PASSWORD_TO_CONFIRM_PASSWORD',
                      })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="passwordConfirmation"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="TITLE" />
                    </p>
                    <TextField
                      id="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_TITLE_IN_CONPANY',
                      })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="ROLE" />
                    </p>
                    <Autocomplete
                      id="role"
                      size="small"
                      options={authorityList}
                      value={values.role}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setFieldValue('role', value);
                        } else {
                          setFieldValue('role', null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'SELECT_ROLE',
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="role"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                    <p className={classes.uppercase}>
                      <FormattedMessage id="GROUPS" />
                    </p>
                    <Autocomplete
                      multiple
                      id="groups"
                      size="small"
                      value={values.groups}
                      options={groups}
                      getOptionLabel={(option) => {
                        if (option[0] && typeof option[0] === 'object') {
                          return option[0].group_name
                            ? option[0].group_name
                            : '';
                        }
                        return option.group_name ? option.group_name : '';
                      }}
                      onChange={(event, value) => {
                        const newValue = [];

                        const justDoIt = Promise.all(
                          value.map(async (v) => {
                            if (!v.group_id) newValue.push(v);
                            else {
                              const newGroup = groups.filter(
                                (group) => group.id === v.group_id,
                              );
                              newValue.push(newGroup[0]);
                            }
                          }),
                        );

                        justDoIt.then(() => {
                          setFieldValue('groups', newValue);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'SELECT_GROUPS_FOR_USER',
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="groups"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <div className={classes.dialogTitle}>
                  <Button
                    onClick={() => {
                      dispatch(fetchGroupsByCompanyId(company.id));
                      setShowModal(false);
                    }}
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    <FormattedMessage id="CANCEL" />
                  </Button>
                  <div>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                      className={classes.dialogButton}
                    >
                      {loading.addUser && (
                        <CircularProgress size="sm" color="inherit" />
                      )}
                      <FormattedMessage id="SAVE" />
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <UserForm
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        user={userName}
        company={company}
      />
    </>
  );
};

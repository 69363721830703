import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { findProjectToCompanyFunction } from 'api/companies';
import { FormattedMessage, useIntl } from 'react-intl';
import CompanyForm from './CompanyForm';
import {
  updateCompany, addProjectToCompany, companiesSelector, deleteProjectToCompany,
} from '../../slices';

const InfoHeader = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #555;
`;

const InfoTıtle = styled.div`
    font-size: 18px;
    line-height: 21px;
    font-weight:bold;
    color: #595469;
`;

const EditButton = styled(Button)`
  float: right !important;
  padding: 8px !important;
  background: #F2F2F2 !important;
  border: 1px solid #D4D4D4 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 2px 4px rgba(219, 219, 219, 0.3) !important;
  border-radius: 5px !important;
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #595469 !important;
  text-transform: none !important;
`;

const InsideGrid = styled(Grid)`
  margin: 24px 16px 24px 8px !important;
`;

const Label = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
`;

const Info = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #595469;
`;
export default ({ company }) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const intl = useIntl();

  const { loading } = useSelector(companiesSelector);

  const handleUpdate = (values) => {
    const { id, company_projects } = values;

    updateCompanyProjects(id, company_projects);

    delete values.projects;
    values.company_id = values.id;
    dispatch(updateCompany(values))
      .then(() => setShowModal(false));
  };

  let updateCompanyProjects = async (id, projects) => {
    const currentProjects = await findProjectToCompanyFunction(id);
    if (projects) {
      const addedProjects = projects.filter((project) => !currentProjects.some((c_project) => c_project.project_id === (project.project_id || project.id)));

      const deletedProjects = currentProjects.filter((c_project) => !projects.some((project) => (project.project_id || project.id) === c_project.project_id));

      for (const project of addedProjects) {
        await dispatch(addProjectToCompany(id, project.project_id || project.id));
      }
      for (const project of deletedProjects) {
        await dispatch(deleteProjectToCompany(id, project.project_id || project.id));
      }
    }
  };
  return (
    <>
      <InfoHeader>
        <InfoTıtle> <FormattedMessage id="COMPANY_INFO" /> </InfoTıtle>
        <EditButton variant="contained" size="small" onClick={() => setShowModal(true)}>
          <FormattedMessage id="UPDATE" />
        </EditButton>
      </InfoHeader>
      <Grid container spacing={3} style={{ padding: '16px' }}>
        <Grid item xs={6} align="left">
          <InsideGrid item xs={12} align="left">
            <Label><FormattedMessage id="COMPANY_NAME" /></Label>
            <Info>{company.company_name}</Info>
          </InsideGrid>
          <InsideGrid item xs={12} align="left">
            <Label><FormattedMessage id="COMPANY_INFO" /></Label>
            <Info>{company.telephone}</Info>
          </InsideGrid>
          <InsideGrid item xs={12} align="left">
            <Label> <FormattedMessage id="FAX_NUMBER" /> </Label>
            <Info>{company.fax}</Info>
          </InsideGrid>
          <InsideGrid item xs={12} align="left">
            <Label><FormattedMessage id="WEB_ADDRESS" /> </Label>
            <Info>{company.web}</Info>
          </InsideGrid>
          <InsideGrid item xs={12} align="left">
            <Label><FormattedMessage id="CONTACT_MAIL" /> </Label>
            <Info>{company.contact_email}</Info>
          </InsideGrid>
        </Grid>
        <Grid item xs={6} align="left">
          <InsideGrid item xs={12}>
            <Label><FormattedMessage id="HEAD_OFFICE_ADDRESS" /> </Label>
            <Info>{company.address}</Info>
          </InsideGrid>
        </Grid>
      </Grid>
      <CompanyForm
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading.updateCompany}
        initialValues={company}
        submitFunc={handleUpdate}
        dialogTitle={intl.formatMessage({ id: 'UPDATE_COMPANY_INFO' }, { company_name: company.company_name })}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { authentication } from 'helpers/authentication';
import device from '../../helpers/device';

import CraneLogoHeader from '../Header/CraneLogoHeader';
import { Footer } from '../Footer';

const LoginAdmin = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(138, 132, 155, 1);
  text-align: center;
  opacity: 1;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 1vh 1vw;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4vh;
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(207, 0, 15, 0.75);
  font-size: calc(0px + 1vw);
  line-height: calc(1px + 1vw);
  color: rgba(255, 255, 255, 1);
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: rgba(250, 100, 0, 1);
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  padding: 0.5vh;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const InputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
  margin-top: 2vh;
  @media ${device.tablet} {
    width: 50vw;
  }

  @media ${device.laptop} {
    width: 25vw;
  }
`;

const LoginInput = styled(Field)`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-radius: 4px;
  border-style: solid;
  border-width: ${(props) => (props.iserror ? '2px' : '1px')};
  border-color: ${(props) => (props.iserror ? 'rgba(207, 0, 15, 1)' : 'rgba(138, 132, 155, 1)')};
  margin: 1vh;
  padding: 1.25vh 1.75vh 1.5vh 2vh;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  text-align: left;
  cursor: pointer;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:focus {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:active {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
`;

const LoginButton = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  width: 100%;
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

const LoginButton2 = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default () => {
  const [error, setError] = useState();

  const intl = useIntl();

  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (!query.get('token')) history.push('/login');
  }, [query, history]);

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED_PASSWORD' }))
      .min(8, intl.formatMessage({ id: 'PASSWORD_IS_TOO_SHORT' }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        intl.formatMessage({ id: 'PASSWORD_MUST_CONTAIN' }),
      ),
  });

  const handleSubmit = (
    { passwordConfirmation, password },
    { setSubmitting, setErrors },
  ) => {
    if (password !== passwordConfirmation) {
      setErrors({
        passwordConfirmation: intl.formatMessage({
          id: 'REQUIRED_PASSWORD_HAVE_TO_MATCH',
        }),
      });
    } else {
      setError();
      authentication
        .resetPassword(query.get('user'), query.get('token'), password)
        .then(() => {
          setSubmitting(false);
          history.push('/login');
        })
        .catch(() => {
          setSubmitting(false);

          setError(intl.formatMessage({ id: 'UNKNOWN_ERROR' }));
        });
    }
  };

  return (
    <LoginAdmin>
      <Container>
        {/* background: linear-gradient(144.58deg, rgba(90, 48, 240, 0.4) 0%, #803AE8 100%); */}

        <CraneLogoHeader />
        <FormContainer>
          <TitleContainer>
            <FormattedMessage
              id="WELCOME_TO"
              values={{ username: query.get('user') }}
            />
          </TitleContainer>
          <TitleContainer>
            <FormattedMessage id="REPASS" />
          </TitleContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <InputContainer>
                  {error && <ErrorContainer>{error}</ErrorContainer>}

                  <LoginInput
                    placeholder={intl.formatMessage({ id: 'PASSWORD' })}
                    type="password"
                    name="password"
                    iserror={errors.password && touched.password ? 1 : 0}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                  />
                  <LoginInput
                    placeholder={intl.formatMessage({
                      id: 'ENTER_PASSWORD_TO_CONFIRM_PASSWORD',
                    })}
                    type="password"
                    name="passwordConfirmation"
                    iserror={
                      errors.passwordConfirmation
                      && touched.passwordConfirmation
                        ? 1
                        : 0
                    }
                  />
                  <ErrorMessage
                    name="passwordConfirmation"
                    component="div"
                    render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                  />
                  <LoginButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="SEND" />
                  </LoginButton>
                </InputContainer>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
      <Footer />
    </LoginAdmin>
  );
};

import React from 'react';
import { Router } from 'react-router-dom';
import 'date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import RouterHelper from 'pages';

import I18nProider from 'i18n/I18nProider';
import { useSelector } from 'react-redux';
import { i18nSelector } from 'slices';
import { history } from './helpers/history';

require('dotenv').config();

const App = styled.div`
  text-align: center;
  height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.backgroundColor};
    margin:0;
    min-height: 100vh;
  }
`;

const lightMode = {
  backgroundColor: '#F6F8FC',
  link: 'rgb(112, 76, 182)',
  text: 'black',
};

// const darkMode = {
//  backgroundColor: 'black',
//  link: 'rgb(112, 76, 182)',
//  text: 'white',
// };

export default () => {
  const { locale } = useSelector(i18nSelector);

  moment.locale(locale);
  return (
    <ThemeProvider theme={lightMode}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={locale}>
        <GlobalStyle />
        <I18nProider locale={locale}>
          <Router history={history}>
            <App>
              <RouterHelper />
            </App>
          </Router>
        </I18nProider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

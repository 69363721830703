import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Breadcrumbs,
  Link,
  makeStyles,
  Paper,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import {
  TitleContainer,
  ContentLink,
  ContentTitle,
  EditButton,
  MainContainer,
  HistoryListContainer,
  ContentContainer,
  CancelButton,
  SaveButton,
} from 'styles/common';
import {
  StyledTabs,
  StyledTab,
  TabPanel,
  FormEditorContainer,
  SendButton,
} from 'components/ProjectForms/FormEditor';
import ProgressCircle from 'components/Common/ProgressCircle';
import { useSelector, useDispatch } from 'react-redux';
import {
  projectsSelector,
  findTaskById,
  tasksSelector,
  statusSetsSelector,
  fetchStatusSets,
  updateForm,
  findTaskDocuments,
  updateTask,
  insertTask,
  insertMail,
  deleteDocuments,
  addDocument,
} from 'slices';
import EditFormValue from 'components/Forms/EditFormValue';
import {
  findByIdTemplatessFunction,
  findRoleByProjectIdCompanyFunc,
} from 'api';
import { Formik, Form } from 'formik';
import Documents from 'components/Forms/Documents';
import { Option } from 'components/User/UserInfo';
import { authentication } from 'helpers/authentication';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import AddTaskModal from '../Forms/AddTaskModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
  },
  rightside: {
    height: '68vh',
  },
  paper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: '100%',
  },
  actions: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignContent: 'center',
    height: '8%',
    padding: theme.spacing(1),
  },
  header: {
    height: theme.spacing(3),
    flex: 1,
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    lineHeight: '17.0px',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
  },
  historyleftside: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px 0`,
  },
  historyrightside: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px`,
  },
  formname: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 'calc(12px + 0.5vh + 0.5vw)',
    color: 'rgba(0,0,0, 0.8)',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    margin: `${theme.spacing(3)}px 0px`,
    alignItems: 'center',
    background: '#dfdfdf',
  },
  document: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 'calc(6px + 0.5vh + 0.5vw)',
    color: 'rgba(0,0,0, 0.8)',
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`,
    margin: `${theme.spacing(2)}px 0px`,
    alignItems: 'center',
    background: 'rgba(245, 245, 245, 1)',
  },
  historyrightone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(250, 100, 105, 1.0)',
    textAlign: 'right',
    lineHeight: '17.0px',
    margin: theme.spacing(0.75),
  },
}));

const FileName = styled.button`
  text-align: left;
  font-size: calc(12px + 0.5vw);
  color: rgba(0, 0, 0, 1);
  margin: 1px;
  width: 100%;
  border: 0;
  cursor: pointer;
  word-break: break-all;
`;

const FileInfo = styled.p`
  text-align: left;
  font-size: calc(12px + 0.5vh);
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  margin: 1px;
`;

const Task = () => {
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [sentValues, setSentValues] = useState(null);
  const [isEditing, setEditing] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [status, setStatus] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [formElements, setFormElements] = useState();
  const [roles, setRoles] = useState([]);

  const [docs, setDocs] = useState([]);

  const { company_id, projects, ...auth } = authentication.userValue;

  const mountedRef = useRef(true);

  const classes = useStyles();

  const history = useHistory();

  const intl = useIntl();

  const { id } = useParams();

  const dispatch = useDispatch();

  const { selectedProject } = useSelector(projectsSelector);
  const {
    task, form, documents, tasks,
  } = useSelector(tasksSelector);
  const { statusSets, statusRoles } = useSelector(statusSetsSelector);

  const projectId = projects[0];

  useEffect(() => {
    if (projectId) {
      findRoleByProjectIdCompanyFunc(projectId, mountedRef.current).then(
        (res) => {
          const _roles = res.filter((role) => role.company_id === company_id);
          setRoles(_roles);
        },
      );
    }

    return () => {
      mountedRef.current = false;
    };
  }, [projectId, company_id]);

  const getInitialValues = (form, subForms) => {
    // eslint-disable-next-line prefer-const
    let a = { ...form.values };
    const initialValues = {
      ...a,
    };

    subForms.forEach((subForm) => {
      if (!initialValues[subForm.name]) {
        initialValues[subForm.name] = {};
      } else {
        initialValues[subForm.name] = {
          ...initialValues[subForm.name],
        };
      }
      subForm.controls.forEach((control) => {
        if (control.type === 'group') {
          if (!initialValues[subForm.name][control.name]) {
            initialValues[subForm.name][control.name] = {};
          }
          control.children.forEach((child) => {
            if (!initialValues[subForm.name][control.name][child.name]) {
              initialValues[subForm.name][control.name] = {
                ...initialValues[subForm.name][control.name],
                [child.name]: child.defaultValue ? [child.defaultValue] : [''],
              };
            }
          });
        } else if (!initialValues[subForm.name][control.name]) {
          initialValues[subForm.name][control.name] = control.defaultValue || '';
        }
      });
    });

    return initialValues;
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    // setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };

  useEffect(() => {
    if (selectedProject) {
      dispatch(findTaskById(id, selectedProject.id));
      dispatch(fetchStatusSets(selectedProject.id));
    }
  }, [selectedProject, id]);

  useEffect(() => {
    if (form && form.template_id) {
      findByIdTemplatessFunction(form.template_id).then((res) => {
        // setTemplate(res);
        if (res) setFormElements({ ...res.template });
      });
    }
  }, [form]);

  useEffect(() => {
    if (formElements && form) {
      setFormValues(getInitialValues(form, formElements.subForms));
      /* setStatus(form.status_set_id); */
    }
  }, [formElements, form]);

  useEffect(() => {
    if (form && statusSets.length) {
      const _selectedStatus = statusSets.filter(
        (status) => status.id === form.status_id,
      )[0];
      const _roles = statusRoles.filter(
        (role) => role.set_id === form.status_id
          && role.roles.some((statusRole) => roles.some((userRole) => statusRole.role_id === userRole.role_id)),
      );
      setSelectedStatus({
        ..._selectedStatus,
        status_set_details: _selectedStatus.status_set_details.filter(
          (detail) => _roles.some((role) => role.detail_id === detail.id),
        ),
      });
    }
  }, [form, statusSets]);

  useEffect(() => {
    setDocs(documents);
  }, [documents]);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  const setState = useCallback(
    ({ payload }) => {
      setOpenTaskModal(payload);
    },
    [setOpenTaskModal],
  );

  return (
    <FormEditorContainer>
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/projects">
            <ContentLink>
              {selectedProject && selectedProject.project_name}
            </ContentLink>
          </Link>
          {id === 'new' ? (
            <ContentTitle>
              {' '}
              <FormattedMessage id="NEW_TASK" />{' '}
            </ContentTitle>
          ) : (
            <span>
              <ContentTitle>
                {' '}
                <FormattedMessage id="TASKS" />
              </ContentTitle>
              &gt;
              <ContentTitle>{form && form.form_no}</ContentTitle>
            </span>
          )}
        </Breadcrumbs>
      </TitleContainer>
      <div className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={{
            formValues: { ...formValues },
            ...form,
          }}
          onSubmit={(fValues, { setFieldError }) => {
            const { formValues: values, ...others } = fValues;
            const key = Object.entries(formValues);

            try {
              let _values;
              key.forEach((subform) => {
                const _subform = values[subform[0]];
                // delete values[subform[0]];
                _values = { ..._values, [subform[0]]: _subform };
              });
              /* const { set_name: location } = form.site_details.site_set || { set_name: null }; */
              const newForm = {
                form_no: form.form_no,
                form_name: form.form_name,
                template_name: form.template_name,
                form_id: form.id,
                original_form_id: form.original_form_id,
                project_id: selectedProject.project_id,
                template_id: form.template_id,
                values: {
                  ..._values,
                },
                site_details: {
                  ...form.site_details,
                },
                revision_id: form.revision_id,
                revision_name: form.revision_name,
                revision_set_id: form.revision_set_id,
                status_set_id: status,
                status_id: form.status_id,
                status_name: form.status_name,
                metadata: {
                  ...form.metadata,
                },
              };
              dispatch(updateForm(newForm)).then(async (updatedForm) => {
                const willAddFiles = docs.filter((element) => !element.id);
                const willDeleteFiles = documents.filter(
                  (element) => docs.filter((el) => el.id === element.id).length === 0,
                );

                for (const deletedFile of willDeleteFiles) {
                  await dispatch(
                    deleteDocuments(
                      updatedForm.original_form_id || updatedForm.id,
                      deletedFile.file_name,
                    ),
                  );
                }

                for (const willAddFile of willAddFiles) {
                  await dispatch(
                    addDocument(
                      updatedForm.original_form_id || updatedForm.id,
                      willAddFile,
                    ),
                  );
                }
                let mailResponse = null;

                if (sentValues) {
                  const oldTask = tasks.filter(
                    (el) => el.step_no !== 3 && el.form_id === form.id,
                  );
                  if (oldTask.length > 0) {
                    const updateTaskResult = await dispatch(
                      updateTask({
                        projectId: selectedProject.id,
                        taskId: oldTask[0].id,
                        formId: updatedForm.id,
                        stepNo: 3,
                        workerId: auth.id,
                        workerName: auth.name,
                        dueDate: sentValues.dueDate,
                      }),
                    );
                  }
                  const toId = 0;
                  const toName = '';
                  const toCompanyId = 0;
                  const toCompanyName = '';

                  let toJSON = {
                    users: [],
                  };

                  let ccJSON = {
                    users: [],
                  };

                  if (sentValues.taskType === 1) {
                    const taskGuid = uuidv4();

                    if (sentValues.actionList.length > 0) {
                      const users = [];
                      sentValues.actionList.forEach((user) => {
                        users.push({
                          id: user.id,
                          name: user.name,
                          companyId: user.company_id,
                          companyName: user.companyName,
                        });
                      });
                      toJSON = {
                        users: [...users],
                      };

                      users.forEach(async (user) => {
                        const response = await dispatch(
                          insertTask({
                            projectId: selectedProject.id,
                            formId: updatedForm.id,
                            workerId: user.id,
                            workerName: user.name,
                            dueDate: sentValues.dueDate,
                            taskType: sentValues.taskType,
                            taskGuid,
                          }),
                        );
                      });
                    }
                    if (sentValues.infoList.length > 0) {
                      const users = [];
                      sentValues.infoList.forEach((user) => {
                        users.push({
                          id: user.id,
                          name: user.name,
                          companyId: user.company_id,
                          companyName: user.companyName,
                        });
                      });
                      ccJSON = {
                        users: [...users],
                      };
                    }
                    mailResponse = await dispatch(
                      insertMail({
                        projectId: selectedProject.id,
                        formId: updatedForm.id,
                        formNo: updatedForm.form_no,
                        formName: updatedForm.form_name,
                        fromId: auth.id,
                        fromName: auth.name,
                        toId,
                        toName,
                        toJSON,
                        ccJSON,
                        description: sentValues.message,
                        fromCompanyId: company_id,
                        fromCompanyName: auth.company_name,
                        toCompanyId,
                        toCompanyName,
                      }),
                    );
                  } else {
                    const taskGuid = uuidv4();

                    if (oldTask.length === 0) {
                      const response = await dispatch(
                        insertTask({
                          projectId: selectedProject.id,
                          formId: updatedForm.id,
                          workerId: auth.id,
                          workerName: auth.name,
                          dueDate: sentValues.dueDate,
                          stepNo: 3,
                          taskType: sentValues.taskType,
                          taskGuid,
                        }),
                      );
                    }

                    if (sentValues.infoList.length > 0) {
                      const users = [];
                      sentValues.infoList.forEach(async (user) => {
                        users.push({
                          id: user.id,
                          name: user.name,
                          companyId: user.company_id,
                          companyName: user.companyName,
                        });
                        const insertResponse = await dispatch(
                          insertTask({
                            projectId: selectedProject.id,
                            formId: updatedForm.id,
                            workerId: user.id,
                            workerName: user.name,
                            dueDate: sentValues.dueDate,
                            stepNo: 3,
                            taskType: sentValues.taskType,
                            taskGuid,
                          }),
                        );
                      });
                      ccJSON = {
                        users: [...users],
                      };
                    }
                    mailResponse = await dispatch(
                      insertMail({
                        projectId: selectedProject.id,
                        formId: updatedForm.id,
                        formNo: updatedForm.form_no,
                        formName: updatedForm.form_name,
                        fromId: auth.id,
                        fromName: auth.name,
                        toId,
                        toName,
                        toJSON: ccJSON,
                        ccJSON: {
                          users: [],
                        },
                        description: sentValues.message,
                        fromCompanyId: company_id,
                        fromCompanyName: auth.company_name,
                        toCompanyId,
                        toCompanyName,
                      }),
                    );
                  }
                }
                setOpenTaskModal(false);
                history.push('/tasks');
                setEditing(false);
              });
            } catch (error) {
            }
          }}
        >
          {({
            errors, touched, handleSubmit, values, isValid,
          }) => (
            <Form style={{ flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {openTaskModal && (
                <AddTaskModal
                  created={task ? task.created_by : null}
                  willOpen={openTaskModal}
                  setState={setState}
                  onSend={(newTaskValues) => {
                    setSentValues(newTaskValues);
                    isValid
                      ? handleSubmit()
                      : alert(
                        intl.formatMessage({ id: 'UNFILLED_FORM_ALERT' }),
                      );
                  }}
                />
                )}
                <Paper className={`${classes.paper} ${classes.rightside}`}>
                  <StyledTabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="form-tabs"
                  >
                    <StyledTab label="FORM" />
                    <StyledTab
                      label={intl.formatMessage({ id: 'ATTACHMENT' })}
                    />
                  </StyledTabs>
                  <TabPanel boxWidth="100%" value={tabIndex} index={0}>
                    {form && task && (
                    <EditFormValue
                      form={form}
                      formValues={formValues}
                      formElements={formElements}
                      disabled={false}
                      projectId={form.project_id}
                    />
                    )}
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <Documents
                      edit
                      documents={docs}
                      original_form_id={form && form.original_form_id}
                      onDone={() => {
                        dispatch(findTaskDocuments(form.original_form_id));
                      }}
                      onAddFile={(fileInfo) => {
                        const tempDocumentList = [...docs];

                        tempDocumentList.push(fileInfo);
                        setDocs(tempDocumentList);

                        // dispatch(
                        //  findTaskDocumentSuccess({
                        //    documents: tempDocumentList
                        //  })
                        // );
                      }}
                      onDeleteFile={(index) => {
                        let tempDocumentList;

                        if (documents.length > 1) {
                          tempDocumentList = [...docs];
                          tempDocumentList.splice(index, 1);
                        } else {
                          tempDocumentList = [];
                        }
                        setDocs(tempDocumentList);
                        // dispatch(
                        //  findTaskDocumentSuccess({
                        //    documents: tempDocumentList
                        //  })
                        // );
                      }}
                    />
                  </TabPanel>
                </Paper>
                <Paper className={classes.actions}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <CancelButton
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        // if (id == 'new') {
                        //   history.push('/tasks');
                        // }
                        history.push('/tasks');
                        /* else if (formChanged) {
                        window.confirm(
                          'Belgeyi kaydetmeden çıkış yapmak yaptığınız değişikliklerin kaybolmasına neden olacaktır. Emin misiniz ?',
                        ) && setInEditPage(0);
                      }
                      else {
                        setInEditPage(0);
                      } */
                        // ref.current.scrollTo(0, 0);
                      }}
                    >
                      <FormattedMessage id="CANCEL" />
                    </CancelButton>
                    <div
                      style={{
                        margin: '15px 0px',
                        width: '50%',
                      }}
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={errors.status_set_id && touched.status_set_id}
                      >
                        <Select
                          margin="dense"
                          variant="outlined"
                          name="status_set_id"
                          value={status ?? 0}
                          fullWidth
                          onChange={handleChange}
                        >
                          <Option value={0} disabled>
                            {`${intl.formatMessage({
                    id: 'PLEASE_SELECT',
                  })}${intl.formatMessage({
                    id: 'STATUS',
                  })}${intl.formatMessage({
                    id: 'PLEASE_SELECT_CONTINUE',
                  })}`}
                          </Option>
                          {selectedStatus
                            && selectedStatus.status_set_details
                              .map((setDetail) => ({
                                ...setDetail,
                                disabled:
                                  statusRoles
                                    .filter(
                                      (statusRole) => statusRole.detail_id === setDetail.id,
                                    )[0]
                                    .roles.filter(
                                      (role) => roles.filter(
                                        (roleElement) => roleElement.role_id === role.role_id,
                                      ).length > 0,
                                    ).length > 0,
                              }))
                              .map((detail) => (detail.disabled ? (
                                <Option key={detail.id} value={detail.id}>
                                  {' '}
                                  {detail.name}{' '}
                                </Option>
                              ) : null))}
                        </Select>
                        <FormHelperText>
                          {touched.status_set_id && errors.status_set_id}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!isEditing ? (
                        <SaveButton
                          variant="contained"
                          size="medium"
                          // disabled={!formChanged}
                          onClick={() => {
                            if (status) {
                              if (isValid) {
                                setEditing(true);
                                handleSubmit();
                              } else {
                                alert(
                                  intl.formatMessage({
                                    id: 'UNFILLED_FORM_ALERT',
                                  }),
                                );
                              }
                            } else {
                              alert(
                                intl.formatMessage({
                                  id: 'REQUIRED_STATUS_SET',
                                }),
                              );
                            }
                          }}
                        >
                          <FormattedMessage id="SAVE" />
                        </SaveButton>
                      ) : (
                        <ProgressCircle
                          height="15vh"
                          size={30}
                          overflow="none"
                        />
                      )}
                      {!isEditing ? (
                        <SendButton
                          variant="contained"
                          size="medium"
                          // disabled={!formChanged}
                          onClick={() => {
                            /* setEditing(true)
                            setTabIndex(1);
                            formikProps.handleSubmit(); */

                            if (status) {
                              isValid
                                ? setOpenTaskModal(true)
                                : alert(
                                  intl.formatMessage({
                                    id: 'UNFILLED_FORM_ALERT',
                                  }),
                                );
                            } else {
                              alert(
                                intl.formatMessage({
                                  id: 'REQUIRED_STATUS_SET',
                                }),
                              );
                            }
                          }}
                        >
                          <FormattedMessage id="SEND" />
                        </SendButton>
                      ) : null}
                    </div>
                  </div>
                </Paper>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormEditorContainer>
  );
};

export default Task;

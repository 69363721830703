import { createSlice } from '@reduxjs/toolkit';
import {
  getTemplatesFunction, addTemplateFunction, updateTemplateFunction,
  deleteTemplateFunction, findByIdTemplatessFunction, searchTemplatesFunction,
  addGroupsWithTemplateIdFuction, deleteGroupsWithTemplateIdFuction, getGroupsByTemplateIdFuction,
  findByProjectIdTemplatesFunction, findByOrginalIdTemplatesFunction, findByMetadataIdTemplatesFunction,
  getMetadatasByTemplateIdFuction, addMetadataWithTemplateIdFuction,
} from 'api/templates';

const initialState = {
  loading: {
    getTemplates: false,
    getTemplateList: false,
    getTemplate: false,
    addTemplate: false,
    updateTemplate: false,
    deleteTemplate: false,
    getTemplateGroup: false,
    addTemplateGroup: false,
    deleteTemplateGroup: false,
    findBıMetadata: false,
  },
  hasErrors: {
    getTemplates: false,
    getTemplateList: false,
    getTemplate: false,
    addTemplate: false,
    updateTemplate: false,
    deleteTemplate: false,
    getTemplateGroup: false,
    addTemplateGroup: false,
    deleteTemplateGroup: false,
    findBıMetadata: false,
  },
  templates: [],
  templateList: [],
  template: null,
  template_groups: [],
  template_metadatas: [],

};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
      switch (payload) {
        case 'getTemplates':
          state.templates = [];
          break;
        case 'getTemplateList':
          state.templateList = [];
          break;
        case 'getTemplate':
          state.template = null;
          break;
        default:
          break;
      }
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getTemplatesSuccess: (state, { payload }) => {
      state.templates = payload;
      state.loading.getTemplates = false;
      state.hasErrors.getTemplates = false;
    },
    findByMetadaIdtIdTemplatesSuccess: (state, { payload }) => {
      state.templates = payload;
      state.loading.findBıMetadata = false;
      state.hasErrors.findBıMetadata = false;
    },
    getTemplateListSuccess: (state, { payload }) => {
      state.templateList = payload;
      state.loading.getTemplateList = false;
      state.hasErrors.getTemplateList = false;
    },
    getTemplateSuccess: (state, { payload }) => {
      state.template = payload;
      state.loading.getTemplate = false;
      state.hasErrors.getTemplate = false;
    },
    addTemplateSuccess: (state, { payload }) => {
      state.templateList = [payload];
      state.loading.addTemplate = false;
      state.hasErrors.addTemplate = false;
    },
    updateTemplateSuccess: (state, { payload }) => {
      state.templateList.unshift(payload);
      state.template = payload;
      state.loading.updateTemplate = false;
      state.hasErrors.updateTemplate = false;
    },
    deleteTemplateSuccess: (state, { payload }) => {
      state.templates.filter((template) => template.id !== payload);
      state.loading.deleteTemplate = false;
      state.hasErrors.deleteTemplate = false;
    },
    getTemplateGroupSuccess: (state, { payload }) => {
      state.template_groups = payload;
      state.loading.getTemplateGroup = false;
      state.hasErrors.getTemplateGroup = false;
    },
    addTemplateGroupSuccess: (state, { payload }) => {
      state.template_groups.push(payload);
      state.loading.addTemplateGroup = false;
      state.hasErrors.addTemplateGroup = false;
    },
    deleteTemplateGroupSuccess: (state, { payload }) => {
      state.template_groups.filter((group) => group.id !== payload);
      state.loading.deleteTemplateGroup = false;
      state.hasErrors.deleteTemplateGroup = false;
    },
    getTemplateMetadataSuccess: (state, { payload }) => {
      state.template_metadatas = payload;
    },
    addTemplateMetadataSuccess: (state, { payload }) => {
      state.template_metadatas.push(payload);
    },

  },
});

export const {
  asyncStart, asyncFailure, getTemplatesSuccess, getTemplateListSuccess, getTemplateSuccess,
  addTemplateSuccess, updateTemplateSuccess, deleteTemplateSuccess,
  getTemplateGroupSuccess, addTemplateGroupSuccess, deleteTemplateGroupSuccess,
  getTemplateMetadataSuccess, addTemplateMetadataSuccess, findByMetadaIdtIdTemplatesSuccess,
} = templatesSlice.actions;

export default templatesSlice.reducer;

export const templatesSelector = (state) => state.templates;

export const fetchTemplate = (templateId) => async (dispatch) => {
  dispatch(asyncStart('getTemplate'));

  try {
    const _template = await findByIdTemplatessFunction(templateId);
    dispatch(getTemplateSuccess(_template));
  } catch (error) {
    dispatch(asyncFailure('getTemplate'));
  }
};

export const fetchTemplateByName = (templateName) => async (dispatch) => {
  dispatch(asyncStart('getTemplateList'));

  try {
    const _templates = await searchTemplatesFunction(templateName);
    dispatch(getTemplateListSuccess(_templates));

    dispatch(fetchTemplate(_templates[0].id));
  } catch (error) {
    dispatch(asyncFailure('getTemplateList'));
  }
};

export const fetchTemplateList = (project_id, original_id) => async (dispatch) => {
  dispatch(asyncStart('getTemplateList'));

  try {
    const _templates = await findByOrginalIdTemplatesFunction(project_id, original_id);
    dispatch(getTemplateListSuccess(_templates));
  } catch (error) {
    dispatch(asyncFailure('getTemplateList'));
  }
};

export const fetchTemplates = () => async (dispatch) => {
  dispatch(asyncStart('getTemplates'));

  try {
    const _templates = await getTemplatesFunction();
    dispatch(getTemplatesSuccess(_templates));
  } catch (error) {
    dispatch(asyncFailure('getTemplates'));
  }
};

export const searchTemplates = (name) => async (dispatch) => {
  dispatch(asyncStart('getTemplates'));

  try {
    const _templates = await searchTemplatesFunction(name);
    dispatch(getTemplatesSuccess(_templates));
  } catch (error) {
    dispatch(asyncFailure('getTemplates'));
  }
};

export const addTemplate = (templateObj) => async (dispatch) => {
  dispatch(asyncStart('addTemplate'));
  try {
    const response = await addTemplateFunction(templateObj);
    dispatch(addTemplateSuccess(response));
    return response;
  } catch (error) {
    dispatch(asyncFailure('addTemplate'));
  }
};

export const updateTemplate = (templateObj) => async (dispatch) => {
  dispatch(asyncStart('updateTemplate'));
  try {
    const newTemplate = await updateTemplateFunction(templateObj);
    dispatch(updateTemplateSuccess(newTemplate));
    return newTemplate;
  } catch (error) {
    dispatch(asyncFailure('updateTemplate'));
  }
};

export const deleteTemplate = (template_id) => async (dispatch) => {
  dispatch(asyncStart('deleteTemplate'));
  try {
    await deleteTemplateFunction(template_id);
    dispatch(deleteTemplateSuccess(template_id));
  } catch (error) {
    dispatch(asyncFailure('deleteTemplate'));
  }
};
export const getTemplateGroup = (template_id) => async (dispatch) => {
  dispatch(asyncStart('getTemplateGroup'));
  try {
    const response = await getGroupsByTemplateIdFuction(template_id);

    dispatch(getTemplateGroupSuccess(response));
    return response;
  } catch (error) {
    dispatch(asyncFailure('getTemplateGroup'));
  }
};
export const addTemplateGroup = (template_id, group_id) => async (dispatch) => {
  dispatch(asyncStart('addTemplateGroup'));
  try {
    const response = await addGroupsWithTemplateIdFuction(template_id, group_id);
    dispatch(addTemplateGroupSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('addTemplateGroup'));
  }
};

export const deleteTemplateGroup = (template_id, group_id) => async (dispatch) => {
  dispatch(asyncStart('deleteTemplateGroup'));
  try {
    await deleteGroupsWithTemplateIdFuction(template_id, group_id);
    dispatch(deleteTemplateGroupSuccess(group_id));
  } catch (error) {
    dispatch(asyncFailure('deleteTemplateGroup'));
  }
};

export const findByProjectIdTemplate = (project_id) => async (dispatch) => {
  dispatch(asyncStart('getTemplates'));
  try {
    const response = await findByProjectIdTemplatesFunction(project_id);
    dispatch(getTemplatesSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getTemplates'));
  }
};

export const findByMetadaIdtIdTemplate = (project_id, metadata_list) => async (dispatch) => {
  dispatch(asyncStart('findBıMetadata'));
  try {
    const template_list = [];
    let list = [];
    for (const metadata of metadata_list) {
      for (const detail of metadata.details) {
        const response = await findByMetadataIdTemplatesFunction(project_id, detail.id);
        list = list.concat(response.filter((r) => list.every((t) => t.id !== r.id)));
      }
      template_list.push({ list });
      list = [];
    }
    const _teplates = template_list[0].list.filter((temp) => template_list.every((t) => t.list.some((el) => el.id === temp.id)));

    dispatch(findByMetadaIdtIdTemplatesSuccess(_teplates));
  } catch (error) {
    dispatch(asyncFailure('findBıMetadata'));
  }
};

export const getTemplateMetadata = (id) => async (dispatch) => {
  try {
    const response = await getMetadatasByTemplateIdFuction(id);

    dispatch(getTemplateMetadataSuccess(response));
    return response;
  } catch (error) {
  }
};
export const addTemplateMetadata = (template_id, metadata_id, metadata_detail_id) => async (dispatch) => {
  try {
    const response = await addMetadataWithTemplateIdFuction(template_id, metadata_id, metadata_detail_id);
    dispatch(addTemplateMetadataSuccess(response));
  } catch (error) {
  }
};

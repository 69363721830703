import axios from 'axios';

import sortFnc from 'helpers/compareHelper';
import APIHelper from '../helpers/APIHelper';
import { AWSConfig } from '../helpers/aws';

// the func used to fetch forms for forms table
export async function getFormListFnc(
  userId,
  projectId,
  limit,
  offset,
  form_name = '',
  form_user = '',
  site_id,
  orderby,
  ordertype,
  status_detail_name = null
) {
  const data = status_detail_name
    ? {
        project_id: projectId,
        user_id: userId,
        limit,
        offset,
        form_name,
        form_user,
        site_id,
        orderby,
        ordertype,
        status_detail_name
      }
    : {
        project_id: projectId,
        user_id: userId,
        limit,
        offset,
        form_name,
        form_user,
        site_id,
        orderby,
        ordertype
      };
  const { forms, recordcount: count, error } = await APIHelper().post(
    'forms/list_by_user_o',
    null,
    data
  );
  if (error) {
    throw new Error(error);
  }

  return { forms, count };
}

export async function getFormsFunction(user_id, project_id, limit, offset) {
  const { forms, recordcount, error } = await APIHelper().post(
    'forms/list_by_user',
    null,
    {
      project_id,
      user_id,
      limit,
      offset
    }
  );
  if (error) {
    throw new Error(error);
  }

  let newForms = [];

  if (forms) {
    const splitToDrafts = async () =>
      Promise.all(
        sortFnc(forms, 'id', false).map((temp) => {
          const {
            id: formId,
            form_name,
            created_at,
            updated_at,
            status,
            original_form_id: orginal,
            version,
            site_details,
            project_id,
            metadata,
            revision_id,
            revision_set_id,
            revision_name,
            template_id,
            form_no,
            status_set_id,
            status_id,
            created_by,
            created_by_name,
            updated_by,
            updated_by_name,
            company_name
          } = temp;
          const { set_name: location } = site_details.site_set || {
            set_name: null
          };
          const filteredList = newForms.filter(
            (filtered) => filtered.original_form_id === orginal
          );
          const unfilteredList = newForms.filter(
            (filtered) => filtered.original_form_id !== orginal
          );
          if (filteredList.length === 0) {
            newForms.unshift({
              id: Number(formId),
              original_form_id: orginal,
              form_name,
              created_at,
              created_by,
              created_by_name,
              updated_at,
              updated_by,
              updated_by_name,
              status,
              version: 1,
              location,
              site_details,
              project_id,
              metadata,
              revision_id,
              revision_set_id,
              revision_name,
              template_id,
              form_no,
              status_id,
              status_set_id,
              company_name,
              drafts: [temp]
            });
          } else {
            newForms = [
              ...unfilteredList,
              {
                id: Number(formId),
                form_name,
                original_form_id: orginal,
                updated_at,
                updated_by,
                updated_by_name,
                created_at,
                created_by,
                created_by_name,
                status,
                version,
                location,
                site_details,
                project_id,
                metadata,
                revision_id,
                revision_set_id,
                revision_name,
                template_id,
                form_no,
                status_id,
                status_set_id,
                company_name,
                drafts: [temp, ...filteredList[0].drafts]
              }
            ];
          }
          return temp;
        })
      );

    return splitToDrafts().then(() => ({
      forms: sortFnc(newForms, 'id', true),
      count: recordcount
    }));
  }
  return error;
}

export async function searchFormsFunction(search) {
  const response = await APIHelper().post('forms/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.forms;
}

const getBase64FromUrl = async (url) =>
  await axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then(async (result) =>
      btoa(
        [].reduce.call(
          new Uint8Array(result.data),
          (p, c) => p + String.fromCharCode(c),
          ''
        )
      )
    );

export async function findByIdFormsFunction(form_id) {
  const response = await APIHelper().post('forms/find', null, { form_id });
  if (response.error) {
    throw new Error(response.error);
  }
  const responseForms = await Promise.all(
    response.forms.map(async (form) => {
      const tempForm = { ...form };
      const { values, ...others } = tempForm;

      const tempValues = { ...values };

      return {
        ...others,
        values: {
          ...tempValues
        }
      };
    })
  );
  return responseForms[0];
}

export async function addFormFunction(formObj) {
  const response = await APIHelper().post('forms/insert', null, { ...formObj });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.form;
}

export async function updateFormFunction(formObj) {
  const response = await APIHelper().post('forms/update', null, { ...formObj });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.form;
}

export async function deleteFormFunction(form_id) {
  const response = await APIHelper().post('forms/delete', null, { form_id });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function findByOriginalIdFormsFunction(original_form_id) {
  const response = await APIHelper().post(
    'forms/find_by_original_form_id',
    null,
    { original_form_id }
  );
  if (response.error) {
    throw new Error(response.error);
  }
  return response.forms;
}

export async function fetchDocumentsFunction(original_form_id) {
  const response = await APIHelper().post('forms/list_document', null, {
    original_form_id
  });
  if (response.error) {
    throw new Error(response.error);
  }

  const documentList = await Promise.all(
    response.document.map(async (doc) => {
      // fileUrl: "https://cranefields.s3-eu-west-1.amazonaws.com/76f9b96f-09eb-4824-a85c-b67b8ca43cbc.png"

      const fileUrl = `https://${AWSConfig.bucketName}.s3-${AWSConfig.region}.amazonaws.com/${doc.file_name}`;
      const fileData = await getBase64FromUrl(fileUrl)
        .then((dataUrl) => dataUrl)
        .catch((error) => null);

      if (fileData) {
        return {
          ...doc,
          data: fileData,
          fileUrl
        };
      }
      const docRes = await APIHelper().post('forms/get_document', null, {
        document_id: doc.id
      });

      return {
        ...doc,
        data: docRes?.document ?? ''
      };
    })
  );
  return documentList;
}

export async function deleteDocumentsFunction(original_form_id, file_name) {
  const response = await APIHelper().post('forms/delete_document', null, {
    original_form_id,
    file_name
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
}

export async function addDocumentFunction(originalFormId, document) {
  const response = await APIHelper().post('forms/add_document_mobile', null, {
    ...document,
    form_original_id: originalFormId
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
}

/* export async function addDocumentFunctionNew(original_form_id, document) {
  const response = await APIHelper().post('forms/add_document_mobile', null, {
    ...document,
    form_original_id: original_form_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
}

export async function addDocumentFunction(original_form_id, form_document) {
  const { token } = authentication.userValue;
  const headers = {
    'content-type': 'multipart/form-data',
    'x-access-token': token,
  };

  const formData = new FormData();

  formData.append('form_document', form_document);
  formData.append('form_original_id', original_form_id);

  const response = await axios.post(
    'https://turna.penguen.ist/api/forms/add_document',
    formData,
    { headers },
  );
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
} */

export async function fetchLogsFunction(form_id) {
  const response = await APIHelper().post('forms/list_eventlogs', null, {
    form_original_id: form_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.eventlogs;
}

export async function fetchFormValuesFunction(form_list) {
  const response = await APIHelper().post('forms/list_d', null, {
    form_list
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.forms.rows;
}

export async function findNextFormNoFunction(projectId, formNo, counterFormat) {
  const response = await APIHelper().post('forms/nextformno', null, {
    project_id: projectId,
    form_no: formNo,
    counter_format: counterFormat
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.nextno;
}

import {
  getTasksByProjectFunction,
  getTasksByWorkerFunction,
  findByIdFormsFunction,
  fetchDocumentsFunction,
  getTaskTemplatesByProjectFunction,
  insertTaskFunction,
  updateTaskFunction,
} from 'api';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  loading: {
    getAllTasks: false, getWorkerTasks: false, findTaskById: false, getTemplates: false, insertTask: false,
  },
  hasErrors: {
    getAllTasks: false, getWorkerTasks: false, findTaskById: false, getTemplates: false, insertTask: false,
  },
  tasks: [],
  workerTasks: [],
  task: null,
  form: null,
  documents: [],
  templates: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getAllTasksSuccess: (state, { payload }) => {
      state.tasks = payload;
      state.loading.getAllTasks = false;
      state.hasErrors.getAllTasks = false;
    },
    getWorkerTasksSuccess: (state, { payload }) => {
      state.workerTasks = payload;
      state.loading.getWorkerTasks = false;
      state.hasErrors.getWorkerTasks = false;
    },
    findTaskSuccess: (state, { payload }) => {
      state.task = payload.task;
      state.form = payload.form;
      state.documents = payload.documents;
      state.loading.findTaskById = false;
      state.hasErrors.findTaskById = false;
    },
    getTemplatesSuccess: (state, { payload }) => {
      state.templates = payload;
      state.loading.getTemplates = false;
      state.hasErrors.getTemplates = false;
    },
    insertTaskSuccess: (state, {
      payload,
    }) => {
      state.loading.insertTask = false;
      state.hasErrors.insertTask = false;
    },
    findTaskDocumentSuccess: (state, { payload }) => {
      state.documents = payload.documents;
      state.loading.findTaskDocuments = false;
      state.hasErrors.findTaskDocuments = false;
    },
  },
});

export const {
  asyncStart, asyncFailure, getAllTasksSuccess, getWorkerTasksSuccess, findTaskSuccess, getTemplatesSuccess, insertTaskSuccess, findTaskDocumentSuccess,
} = tasksSlice.actions;

export default tasksSlice.reducer;

export const tasksSelector = (state) => state.tasks;

export const getAllTasks = (project_id) => async (dispatch) => {
  dispatch(asyncStart('getAllTasks'));
  try {
    const response = await getTasksByProjectFunction(project_id);
    dispatch(getAllTasksSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getAllTasks'));
  }
};

export const getWorkerTasks = (project_id, worker_id) => async (dispatch) => {
  dispatch(asyncStart('getWorkerTasks'));
  try {
    const response = await getTasksByWorkerFunction(project_id, worker_id);
    dispatch(getWorkerTasksSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getWorkerTasks'));
  }
};

export const findTaskById = (task_id, project_id) => async (dispatch, mainState) => {
  dispatch(asyncStart('findTaskById'));
  const { tasks: state } = mainState();
  try {
    let _tasks = state.tasks;
    if (!_tasks.length) {
      _tasks = await getTasksByProjectFunction(project_id);
    }
    const _task = _tasks.filter((task) => task.id == task_id)[0];

    let _form = null;
    let _documents = [];
    if (_task) {
      _form = await findByIdFormsFunction(_task.form_id);
    }
    if (_form) {
      _documents = await fetchDocumentsFunction(_form.original_form_id);
    }

    dispatch(findTaskSuccess({ task: _task, form: _form, documents: _documents }));
  } catch (error) {
    dispatch(asyncFailure('findTaskById'));
  }
};

export const findTaskDocuments = (originalFormId) => async (dispatch) => {
  dispatch(asyncStart('findTaskDocuments'));
  try {
    const documents = await fetchDocumentsFunction(originalFormId);

    dispatch(findTaskDocumentSuccess({ documents }));
  } catch (error) {
    dispatch(asyncFailure('findTaskDocuments'));
  }
};

export const getTaskTemplates = (project_id) => async (dispatch) => {
  dispatch(asyncStart('getTemplates'));
  try {
    const response = await getTaskTemplatesByProjectFunction(project_id);
    dispatch(getTemplatesSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('getTemplates'));
  }
};

export const insertTask = ({
  projectId,
  formId,
  workerId,
  workerName,
  dueDate,
  stepNo,
  status,
  taskType,
  taskGuid,
}) => async (dispatch) => {
  dispatch(asyncStart('insertTask'));

  try {
    const response = await insertTaskFunction({
      projectId,
      formId,
      workerId,
      workerName,
      dueDate,
      stepNo: stepNo || 1,
      status: status || 0,
      taskType: taskType || 1,
      taskGuid,
    });
    return dispatch(insertTaskSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('insertTask'));
  }
};

export const updateTask = ({
  projectId,
  taskId,
  formId,
  workerId,
  workerName,
  dueDate,
  stepNo,
  status,
}) => async (dispatch) => {
  dispatch(asyncStart('insertTask'));

  try {
    const response = await updateTaskFunction({
      projectId,
      taskId,
      formId,
      workerId,
      workerName,
      dueDate,
      stepNo: stepNo || 1,
      status: status || 0,
    });
    return dispatch(insertTaskSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('insertTask'));
  }
};

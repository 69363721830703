import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { Paper, Grid, Modal } from '@material-ui/core';

import moment from 'moment';
import { BsImageAlt, BsTrash } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import 'react-html5-camera-photo/build/css/index.css';

import { authentication } from 'helpers/authentication';
import OutlinedDiv from 'components/Common/OutlineDiv';
import { useSelector } from 'react-redux';
import { InputLabel } from '../Containers';

// const UpperInfoText = styled.p`
//   color: rgba(89, 84, 105, 1) !important;
//   font-size: calc(4px + 0.45vh + 0.45vw);
//   line-height: calc((4px + 0.45vh + 0.45vw) * 1.618);
//   letter-spacing: calc((4px + 0.45vh + 0.45vw) / (1.618 * 20));
//   margin-left: auto;
//   margin-right: auto;
// `;

const InfoText = styled.p`
  color: rgba(89, 84, 105, 1) !important;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
`;

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex,
}) => {
  const [extraInputsList, setExtraInputsList] = useState(extraInputs);
  useEffect(() => {}, [extraInputsList]);

  const fileInput = useRef(null);

  const intl = useIntl();

  const {
    name, label, hint, required,
  } = input;

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  return (
    <Field name={fieldName} validate={(vv) => {}}>
      {(props) => {
        const { field, form, meta } = props;

        const {
          values: {
            status_name,
            status_set_id,
            status_id,
            site_details: {
              site_list,
              site_set: { set_name },
            },
          },
        } = form;

        const { name: created_by, company_name } = authentication.userValue;

        const image_status_name = useSelector(
          ({ statusSets }) => statusSets?.statusSets
            ?.find((status) => status.id === Number(status_id))
            ?.status_set_details?.find(
              (detail) => detail.id === Number(status_set_id),
            )?.name,
        );

        useEffect(() => {
          onHandleStatusChangedSuccessfullyFinished(image_status_name);
        }, [status_set_id]);

        const fileList = extraInputsList && extraInputsList.length > 0
          ? field.value && field.value[stateIndex]
          : field.value;

        const onHandleFilesAddedSuccessfullyFinished = (fileListArrayItems) => {
          form.setFieldValue(
            extraInputsList && extraInputsList.length > 0
              ? `${fieldName}.${stateIndex}`
              : fieldName,
            fileList.concat(fileListArrayItems),
          );
        };

        const onHandleStatusChangedSuccessfullyFinished = (status) => {
          const imageList = extraInputsList && extraInputsList.length > 0
            ? field.value && field.value[stateIndex]
            : field.value;

          form.setFieldValue(
            extraInputsList && extraInputsList.length > 0
              ? `${fieldName}.${stateIndex}`
              : fieldName,
            Array.isArray(imageList)
              ? imageList?.map((image) => {
                if (!image?.fileUrl) {
                // if the image does not have file url property it means the image is not saved
                  return { ...image, status };
                }
                return image;
              }) : [],
          );
        };

        const handleLogoChange = (files, index) => {
          const filesArray = [];
          Object.values(files).forEach((file, i) => {
            const reader = new FileReader();

            if (file && file.type.match('image.*')) {
              reader.readAsDataURL(file);

              reader.onload = async (readerEvent) => {
                if (file.type.substr(0, 6) === 'image/') {
                  const fileName = uuidv4();
                  let extension = file.name;
                  const lastDotPosition = file.name.lastIndexOf('.');
                  if (lastDotPosition !== -1) {
                    extension = extension.substr(
                      lastDotPosition,
                      file.name.length,
                    );
                  }
                  const newPhoto = {
                    file,
                    data: reader.result.split('base64,').pop(),
                    fileName: `${fileName}${extension}`,
                    fileSize: `${Math.round(file.size / 1000)} kB`,
                    created_by,
                    company_name,
                    created_at: moment().format('DD.MM.YYYY HH:mm'),
                    status: image_status_name,
                  };
                  filesArray.push({ ...newPhoto });
                  if (i === files.length - 1) {
                    onHandleFilesAddedSuccessfullyFinished(filesArray);
                  }
                } else {
                  window.alert(
                    intl.formatMessage({ id: 'ENTER_ONLY_IMAGE_FILE' }),
                  );
                }
              };
            }
          });
        };

        // active disable input for image upload
        const handleClick = (e) => {
          e.preventDefault();
          fileInput.current.click();
        };

        const handleDelete = (index) => {
          let tempFileList;

          if (fileList?.length > 1) {
            tempFileList = [...fileList];
            tempFileList.splice(index, 1);
          } else {
            tempFileList = [];
          }

          form.setFieldValue(
            extraInputsList && extraInputsList.length > 0
              ? `${fieldName}.${stateIndex}`
              : fieldName,
            tempFileList,
          );
        };

        const i = 0;
        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <InputLabel>{`${labelText}${required ? ' (*)' : ''}`}</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={3}>
                <OutlinedDiv
                  helperText={meta.error}
                  error={!!meta.error}
                  disabled={disabled}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Modal
                      open={modal}
                      onClose={() => setModal(false)}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            color: '#fff',
                            position: 'absolute',
                            fontSize: 32,
                            top: '2%',
                            right: '6%',
                            cursor: 'pointer',
                          }}
                          onClick={() => setModal(false)}
                        >
                          X
                        </div>
                        <img
                          style={{
                            width: '30%',
                          }}
                          src={selectedImage}
                        />
                      </div>
                    </Modal>
                    {fileList
                    && Array.isArray(fileList)
                    && fileList.length > 0 ? (
                      <Grid
                        xs={9}
                        container
                        item
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        {fileList.map((file, index) => {
                          if (
                            file === ''
                            || !file
                            || !file.fileName
                            || (file && Array.isArray(file) && file.length === 0)
                          ) { return null; }

                          const fileExt = file.fileName.split('.').pop();
                          const base64Doc = `data:image/${fileExt};base64,${file.data}`;

                          return (
                            <div
                              key={file.fileName}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <img
                                key={`image-input-${file.fileName}`}
                                style={{
                                  width: '100px',
                                  height: 'auto',
                                  margin: '0.5vh 0.5vw',
                                }}
                                src={file?.fileUrl || base64Doc}
                                alt={file.fileName}
                                onClick={() => {
                                  setModal(true);
                                  setSelectedImage(file.fileUrl);
                                  // const downloadLink = window.document.createElement(
                                  //   'a',
                                  // );
                                  // const { fileName } = file;
                                  // downloadLink.href = base64Doc;
                                  // downloadLink.download = fileName;
                                  // downloadLink.click();
                                }}
                              />
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: '0.5vh 0.5vw',
                                  }}
                                >
                                  <InputLabel>
                                    {[{ name: set_name }, ...site_list]
                                      .map((el) => el.name)
                                      .join(' > ')}
                                  </InputLabel>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: '0.5vh 0.5vw',
                                  }}
                                >
                                  <InputLabel>
                                    {file.created_by || file.fileName}
                                  </InputLabel>
                                  <span style={{ color: '#000' }}>
                                    &nbsp;-&nbsp;
                                  </span>
                                  <InputLabel>{file.created_at}</InputLabel>
                                </div>
                                <div
                                  style={{
                                    width: '10vw',
                                    height: 'auto',
                                    borderRadius: '2px',
                                    backgroundColor: '#E5E5E5',
                                    padding: '1vh 1vw',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={
                                    !disabled
                                      ? () => handleDelete(index)
                                      : () => {}
                                  }
                                >
                                  <BsTrash size={24} color="#525A65" />
                                  <div
                                    style={{
                                      fontFamily: 'Helvetica !important',
                                      fontSize: 'calc(4px + 0.5vh + 0.5vw)',
                                      lineHeight:
                                        'calc((4px + 0.5vh + 0.5vw) * 1.618)',
                                      letterSpacing:
                                        'calc((4px + 0.5vh + 0.5vw) / (1.618 * 20))',
                                      paddingLeft: '1vw',
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'DELETE' })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Grid>
                      ) : null}

                    <Grid xs>
                      <div
                        style={{
                          border: '2px dashed #E4E6E7',
                          padding: '1vh 1vw',
                          cursor: 'pointer',
                        }}
                        onClick={!disabled ? handleClick : () => {}}
                      >
                        {/* <UpperInfoText>
                          {intl.formatMessage({ id: 'UPLOAD_AND_CROP' })}
                        </UpperInfoText> */}
                        <div
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            backgroundColor: '#E5E5E5',
                            padding: '16px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <BsImageAlt size={48} color="#525A65" />
                        </div>
                        <InfoText>
                          {intl.formatMessage({ id: 'BROWSE_FILES_IMAGE' })}
                        </InfoText>
                      </div>
                    </Grid>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleLogoChange(e.target.files, stateIndex);
                      }}
                      multiple
                      ref={fileInput}
                      style={{ display: 'none' }}
                    />
                  </Grid>
                </OutlinedDiv>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};

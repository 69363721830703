import { createSlice } from '@reduxjs/toolkit';
import S3 from 'react-aws-s3';

import {
  getFormsFunction,
  addFormFunction,
  updateFormFunction,
  deleteFormFunction,
  findByOriginalIdFormsFunction,
  findByIdFormsFunction,
  fetchDocumentsFunction,
  addDocumentFunction,
  deleteDocumentsFunction,
  fetchLogsFunction,
  fetchFormValuesFunction,
  findNextFormNoFunction,
  getFormListFnc,
} from 'api';

import { AWSConfig } from '../helpers/aws';

const ReactS3Client = new S3(AWSConfig);

const initialState = {
  loading: {
    getForms: false,
    addForm: false,
    updateForm: false,
    deleteForm: false,
    getDraftList: false,
    findByIdForm: false,
    fetchDocuments: false,
    addDocument: false,
    deleteDocument: false,
    fetchLogs: false,
  },
  hasErrors: {
    getForms: false,
    addForm: false,
    updateForm: false,
    deleteForm: false,
    getDraftList: false,
    findByIdForm: false,
    fetchDocuments: false,
    addDocument: false,
    deleteDocument: false,
    fetchLogs: false,
  },
  forms: [],
  selectedMetadataSet: [],
  selectedSiteSet: null,
  selectedTemplate: null,
  draftList: [],
  form: null,
  documents: [],
  site_list: [],
  logs: [],
  formTableList: [],
  formFilters: null,
  formsCount: 0,
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getFormsSuccess: (state, { payload }) => {
      state.forms = payload.forms;
      state.loading.getForms = false;
      state.hasErrors.getForms = false;
      state.formsCount = payload.count;
    },
    getFormListSuccess: (state, { payload }) => {
      state.formList = payload.forms;
      state.loading.getFormList = false;
      state.hasErrors.getForms = false;
      state.formsCount = payload.count;
    },
    addFormSuccess: (state, { payload }) => {
      state.forms.unshift(payload);
      state.loading.addForm = false;
      state.hasErrors.addForm = false;
    },
    updateFormSuccess: (state, { payload: { response, newDraft } }) => {
      state.forms = state.forms.map((form) => {
        if (form.original_form_id === response.original_form_id) {
          return form.drafts.unshift(response);
        }
        return form;
      });
      state.draftList.unshift(newDraft);

      state.loading.updateForm = false;
      state.hasErrors.updateForm = false;
    },
    deleteFormSuccess: (state, { payload }) => {
      state.forms.filter((form) => form.id !== payload);
      state.loading.deleteForm = false;
      state.hasErrors.deleteForm = false;
    },
    selectMetadataSet: (state, { payload }) => {
      if (!payload) {
        state.selectedMetadataSet = [];
      } else if (
        state.selectedMetadataSet.every((metada) => metada.id !== payload.id)
      ) { state.selectedMetadataSet.push(payload); }
    },
    removeMetadataSet: (state, { payload }) => {
      state.selectedMetadataSet = state.selectedMetadataSet.filter(
        (metada) => metada.id !== payload,
      );
    },
    addSiteList: (state, { payload }) => {
      if (!payload) {
        state.site_list = [];
      } else {
        state.site_list = payload;
      }
    },
    removeSiteList: (state, { payload }) => {
      state.site_list = state.site_list.filter((site) => site.id !== payload);
    },
    deleteSiteList: (state, { payload }) => {
      state.site_list = [];
    },
    selectSiteSet: (state, { payload }) => {
      state.selectedSiteSet = payload;
    },
    selectTemplate: (state, { payload }) => {
      state.selectedTemplate = payload;
    },
    getDraftListSuccess: (state, { payload }) => {
      state.draftList = payload;
      state.loading.getDraftList = false;
      state.hasErrors.getDraftList = false;
    },
    findByIdFormSuccess: (state, { payload }) => {
      state.form = payload;
      state.loading.findByIdForm = false;
      state.hasErrors.findByIdForm = false;
    },
    findByProjectIdForm: (state, { payload }) => {
      state.forms = state.forms.filter((form) => form.project_id === payload);
    },
    getDocumentsSuccess: (state, { payload }) => {
      state.documents = payload;
      state.loading.fetchDocuments = false;
      state.hasErrors.fetchDocuments = false;
    },
    addDocumentSuccess: (state, { payload }) => {
      state.documents.unshift(payload);
      state.loading.addDocument = false;
      state.hasErrors.addDocument = false;
    },
    deleteDocumentsSuccess: (state, { payload }) => {
      state.documents = state.documents.filter(
        (doc) => doc.file_name !== payload,
      );
      state.loading.deleteDocument = false;
      state.hasErrors.deleteDocument = false;
    },
    resetDocuments: (state, { payload }) => {
      state.documents = [];
    },
    fetchLogsSuccess: (state, { payload }) => {
      state.logs = payload;
      state.loading.fetchLogs = false;
      state.hasErrors.fetchLogs = false;
    },
    setFormTableList: (state, { payload }) => {
      state.formTableList = payload;
    },
    setFormFilters: (state, { payload }) => {
      state.formFilters = payload;
    },
  },
});

export const {
  asyncStart,
  asyncFailure,
  getFormsSuccess,
  addFormSuccess,
  updateFormSuccess,
  deleteFormSuccess,
  selectMetadataSet,
  selectSiteSet,
  getDraftListSuccess,
  findByIdFormSuccess,
  findByProjectIdForm,
  removeMetadataSet,
  getDocumentsSuccess,
  addDocumentSuccess,
  deleteDocumentsSuccess,
  selectTemplate,
  addSiteList,
  removeSiteList,
  fetchLogsSuccess,
  deleteSiteList,
  setFormTableList,
  setFormFilters,
  resetDocuments,
  getFormListSuccess,
} = formsSlice.actions;

export default formsSlice.reducer;

export const formsSelector = (state) => state.forms;

export const fetchForms = (user_id, project_id, limit, offset) => async (
  dispatch,
) => {
  dispatch(asyncStart('getForms'));

  try {
    const res = await getFormsFunction(user_id, project_id, limit, offset);
    dispatch(getFormsSuccess(res));
  } catch (error) {
    dispatch(asyncFailure('getForms'));
  }
};

// the action used to fetch grouped forms by original_form_id
export const fetchFormList = (
  userId,
  projectId,
  limit,
  offset,
  formName,
  formUser,
  site_id = 0,
  orderby,
  ordertype,
  status_detail_name = null,
) => async (dispatch) => {
  try {
    dispatch(asyncStart('getFormList'));
    const res = await getFormListFnc(
      userId,
      projectId,
      limit,
      offset,
      formName,
      formUser,
      `${site_id}`,
      orderby,
      ordertype,
      status_detail_name,
    );
    dispatch(getFormListSuccess(res));
  } catch (error) {
    dispatch(asyncFailure('getFormList'));
  }
};

async function uploadFile(file) {
  let { fileName } = file;
  const lastDotPosition = fileName.lastIndexOf('.');
  if (lastDotPosition !== -1) fileName = fileName.substr(0, lastDotPosition);
  let dataLocation = null;
  try {
    dataLocation = await ReactS3Client.uploadFile(file.file, fileName)
      .then((data) => data.location)
      .catch((err) => null);
  } catch (error) {
    try {
      dataLocation = await ReactS3Client.uploadFile(file.file, fileName)
        .then((data) => data.location)
        .catch((err) => null);
    } catch (err) {
      dataLocation = null;
    }
  }
  return dataLocation;
}

async function doWorks(file) {
  if (file.file && !file.fileUrl) {
    const fileUrl = await uploadFile(file);

    if (fileUrl) {
      const tempFileObject = {
        ...file,
        fileUrl,
      };
      delete tempFileObject.file;
      delete tempFileObject.data;

      return { ...tempFileObject };
    }
    return file;
  }
  if (file.fileUrl && file.data) {
    const tempFileObject = {
      ...file,
    };
    delete tempFileObject.data;

    return { ...tempFileObject };
  }
  return file;
}

async function processImageElement(formObject) {
  const tempValues = { ...formObject.values };
  const generatedResponse = { ...tempValues };
  const tempGeneratedResponse = JSON.parse(JSON.stringify(generatedResponse));

  for (const subFormName of Object.keys(generatedResponse)) {
    for (const control of Object.keys(generatedResponse[subFormName])) {
      if (
        typeof generatedResponse[subFormName][control] === 'object'
        && generatedResponse[subFormName][control] !== null
        && !Array.isArray(generatedResponse[subFormName][control])
      ) {
        for (const input of Object.keys(
          generatedResponse[subFormName][control],
        )) {
          if (Array.isArray(generatedResponse[subFormName][control][input])) {
            for (
              let stateIndex = 0;
              stateIndex
              < generatedResponse[subFormName][control][input].length;
              stateIndex += 1
            ) {
              if (
                Array.isArray(
                  generatedResponse[subFormName][control][input][stateIndex],
                )
              ) {
                for (
                  let fileIndex = 0;
                  fileIndex
                  < generatedResponse[subFormName][control][input][stateIndex]
                    .length;
                  fileIndex += 1
                ) {
                  tempGeneratedResponse[subFormName][control][input][
                    stateIndex
                  ][fileIndex] = await doWorks(
                    generatedResponse[subFormName][control][input][stateIndex][
                      fileIndex
                    ],
                  );
                }
              }
            }
          }
        }
      }
      if (Array.isArray(generatedResponse[subFormName][control])) {
        for (
          let fileIndex = 0;
          fileIndex < generatedResponse[subFormName][control].length;
          fileIndex += 1
        ) {
          tempGeneratedResponse[subFormName][control][
            fileIndex
          ] = await doWorks(generatedResponse[subFormName][control][fileIndex]);
        }
      }
    }
  }
  return {
    ...formObject,
    values: {
      ...tempGeneratedResponse,
    },
  };
}

export const addForm = (formObj) => async (dispatch) => {
  dispatch(asyncStart('addForm'));
  try {
    const tempValues = await processImageElement(formObj);

    const newForm = await addFormFunction(tempValues);

    dispatch(addFormSuccess(newForm));
    return newForm;
  } catch (error) {
    dispatch(asyncFailure('addForm'));
    throw error;
  }
};

export const updateForm = (formObj) => async (dispatch) => {
  dispatch(asyncStart('updateForm'));
  try {
    const tempValues = await processImageElement(formObj);

    const response = await updateFormFunction(tempValues);
    const {
      revision_set_id: revisionSetId,
      revision_name: revisionSetName,
    } = response;
    const newDraft = {
      revisionSetName,
      revisionSetId,
      revisionList: [response],
    };
    dispatch(updateFormSuccess({ response, newDraft }));
    return response;
  } catch (error) {
    dispatch(asyncFailure('updateForm'));
    throw error;
  }
};

export const deleteForm = (form_id) => async (dispatch) => {
  dispatch(asyncStart('deleteForm'));
  try {
    await deleteFormFunction(form_id);
    dispatch(deleteFormSuccess(form_id));
  } catch (error) {
    dispatch(asyncFailure('deleteForm'));
  }
};

export const fetchDraftList = (original_id) => async (dispatch, state) => {
  dispatch(asyncStart('getDraftList'));
  try {
    const response = await findByOriginalIdFormsFunction(original_id);

    const draftList = [];

    response
      .sort((a, b) => b.revision_set_id - a.revision_set_id)
      .forEach((draft) => {
        const {
          revision_set_id: revisionSetId,
          revision_id: revisionId,
        } = draft;

        let revIndex = -1;
        const revision = draftList.find((element, index) => {
          if (element.revisionSetId === revisionSetId) {
            revIndex = index;
            return true;
          }
          return false;
        });

        if (!revision) {
          draftList.push({
            revisionSetName: state()
              .revisionSets.revisionSets.find(
                (revisionSet) => revisionSet.id === revisionId,
              )
              .revision_set_details.find(
                (setDetail) => setDetail.id === revisionSetId,
              ).name,
            revisionSetId,
            revisionList: [draft],
          });
        } else {
          const tempRevision = { ...revision };

          tempRevision.revisionList = tempRevision.revisionList
            .concat([draft])
            .sort((a, b) => b.version - a.version);

          draftList[revIndex] = { ...tempRevision };
        }
      });

    dispatch(getDraftListSuccess(draftList));
  } catch (error) {
    dispatch(asyncFailure('getDraftList'));
  }
};

export const findByIdForm = (id, emptyForm) => async (dispatch, state) => {
  dispatch(asyncStart('findByIdForm'));
  try {
    if (!id && !emptyForm) {
      dispatch(findByIdFormSuccess(null));
    } else if (id === 'new') {
      const formNo = await findNextFormNoFunction(
        emptyForm.project_id,
        emptyForm.form_no,
        emptyForm.counter_format,
      );
      dispatch(findByIdFormSuccess({ ...emptyForm, form_no: formNo }));
    } else {
      // let [_form] = state().forms.forms.filter((form) => form.id ===   Number(id));
      // if (!_form){
      const _form = await findByIdFormsFunction(id);
      // }else{
      //  let [{values}]  =await fetchFormValuesFunction(`${id}`);
      //  _form = {..._form, values};
      // }
      dispatch(findByIdFormSuccess(_form));
    }
  } catch (error) {
    dispatch(asyncFailure('findByIdForm'));
  }
};

export const fetchDocuments = (original_form_id) => async (dispatch) => {
  dispatch(asyncStart('fetchDocuments'));
  if (!original_form_id) {
    dispatch(getDocumentsSuccess([]));
  } else {
    try {
      const response = await fetchDocumentsFunction(original_form_id);
      dispatch(getDocumentsSuccess(response));
    } catch (error) {
      dispatch(asyncFailure('fetchDocuments'));
    }
  }
};

export const deleteDocuments = (original_form_id, file_name) => async (
  dispatch,
) => {
  dispatch(asyncStart('deleteDocuments'));
  try {
    const response = await deleteDocumentsFunction(original_form_id, file_name);
    dispatch(deleteDocumentsSuccess(file_name));
  } catch (error) {
    dispatch(asyncFailure('deleteDocuments'));
  }
};

const documentHandler = async (file) => {
  const fileUrl = await uploadFile(file);

  if (fileUrl) {
    const tempFileObject = {
      ...file,
      fileUrl,
    };
    delete tempFileObject.file;
    delete tempFileObject.data;

    return { ...tempFileObject };
  }
  return file;
};

export const addDocument = (original_form_id, file) => async (dispatch) => {
  dispatch(asyncStart('addDocument'));
  try {
    const newFile = await documentHandler(file);

    const response = await addDocumentFunction(original_form_id, newFile);
    dispatch(fetchDocuments(original_form_id));
    // dispatch(addDocumentSuccess({...file, id: "new"}));
  } catch (error) {
    dispatch(asyncFailure('addDocument'));
  }
};

export const fetchLogs = (form_id) => async (dispatch) => {
  dispatch(asyncStart('fetchLogs'));
  try {
    const response = await fetchLogsFunction(form_id);

    dispatch(fetchLogsSuccess(response));
  } catch (error) {
    dispatch(asyncFailure('fetchLogs'));
  }
};

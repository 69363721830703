import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Icon, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { IoMdClose } from 'react-icons/io';
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';

import { useDispatch, useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { useIntl, FormattedMessage } from 'react-intl';
import { Cancel } from '@material-ui/icons';
import { Checkbox } from '../Form';

import ProgressCircle from '../Common/ProgressCircle';
import {
  projectsSelector,
  usersSelector,
  fetchUsersByProjectId,
} from '../../slices';

registerLocale('tr', tr);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 20,
    color: 'rgba(89, 84, 105, 1.0)',
    lineHeight: '24px',
  },
  body: {
    flex: 1,
    maxHeight: '70vh',
    overflowY: 'scroll',
    padding: theme.spacing(2),
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  headerinside: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  bodyinside: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputGroup: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  checkGroup: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  buttonGroup: {
    padding: theme.spacing(1),
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
    fontWeight: 'bold',
  },
  groupUl: {
    paddingLeft: 24,
  },
}));

const TaskTypeButton = styled(Button)`
  align-items: center;
  background-image: ${(props) => (props.taskType === props.thisButton
    ? 'linear-gradient(to left, #ff963e, #fa6400)'
    : 'linear-gradient(to left, #cfd8dc, #b0bec5)')};
  color: #ffffff !important;
  border-radius: 5px !important;
  opacity: 0.8;
  transition: all 300ms;
  width: 100%;
  margin-top: 8px !important;
  margin-bottom: 8px !important;

  &:hover {
    opacity: 1;
  }
`;

const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

const DateInput = styled(DatePicker)`
  background-color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'white')};
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: 'rgba(223, 227, 233, 1)'};
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16.63px;
  color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'rgba(92, 93, 93, 1)')};
  text-align: left;
  line-height: 24.95px;
  opacity: ${(props) => (props.disabled ? '1' : '0.8')};
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const InputTitle = styled.div`
  flex: 1;
  color: rgba(89, 84, 105, 1);
  font-weight: bold;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
`;

export default memo(({
  willOpen, onSend, created, setState,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedProject } = useSelector(projectsSelector);
  const { users, loading } = useSelector(usersSelector);

  const [started, setStart] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [taskType, setTaskType] = useState(1);

  const [sent, setSend] = useState(false);
  const [open, setOpen] = useState(false);

  const [optionList, setOptionList] = useState([]);

  const intl = useIntl();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setState({ type: 'setOpenTaskModal', payload: false });
  };

  useEffect(() => {
    if (selectedProject) {
      dispatch(fetchUsersByProjectId(selectedProject.id));
      setStart(true);
    }
  }, []);

  useEffect(() => {
    if (willOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [willOpen, handleClose]);

  const getInitialValues = () => {
    let taskCreator = null;
    if (created) {
      taskCreator = users.find((user) => user.id === created);
    }

    const forInfoList = [];
    // if (taskCreator) {
    //  forInfoList.push(taskCreator);
    // }

    setInitialValues({
      forInfoList,
      forActionList: [],
      responseRequired: false,
      dueDate: null,
      message: '',
      taskType,
    });
  };

  useEffect(() => {
    if (!loading.getUsers && started) {
      getInitialValues();

      const _users = users.filter((u) => Number(u.role) > 1);

      const userList = [
        ..._users.map((user) => ({
          ...user,
          type: 'USER',
        })),
      ];

      const companyList = [];
      const groupList = [];

      for (const user of _users) {
        const isCompany = companyList.findIndex(
          (element) => element.cId === user.company_id && element.type === 'COMPANY',
        );
        if (isCompany === -1) {
          companyList.push({
            cId: user.company_id,
            type: 'COMPANY',
            name: user.companyName,
            range: 1,
          });
        } else {
          companyList[isCompany] = {
            ...companyList[isCompany],
            range: companyList[isCompany].range + 1,
          };
        }
        for (const group of user.groups) {
          const isGroup = groupList.findIndex(
            (element) => element.gId === group.group_id && element.type === 'GROUP',
          );
          if (isGroup === -1) {
            groupList.push({
              gId: group.group_id,
              type: 'GROUP',
              name: `${user.companyShortName} - ${group.group_name}`,
              range: 1,
            });
          } else {
            groupList[isGroup] = {
              ...groupList[isGroup],
              range: groupList[isGroup].range + 1,
            };
          }
        }
      }

      const tempList = [...companyList, ...groupList, ...userList];
      setOptionList(tempList);
    }
  }, [users]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper} width={2 / 3}>
          {started
            && !loading.getUsers
            && users.length > 0
            && Object.keys(initialValues).length > 0 ? (
              <Formik
                initialValues={{ ...initialValues }}
                onSubmit={(values) => {
                  // handleClose();
                  if (!sent) {
                    if (taskType === 1) {
                      if (values.forActionList.length !== 0) {
                        setSend(true);
                        onSend({
                          actionList: values.forActionList,
                          infoList: values.forInfoList,
                          responseRequired: values.responseRequired,
                          dueDate: values.dueDate,
                          message: values.message,
                          taskType,
                        });
                      }
                    } else if (values.forInfoList.length !== 0) {
                      setSend(true);
                      onSend({
                        actionList: values.forActionList,
                        infoList: values.forInfoList,
                        responseRequired: values.responseRequired,
                        dueDate: values.dueDate,
                        message: values.message,
                        taskType,
                      });
                    }
                  }
                }}
              >
                {({
                  handleChange, values, setFieldValue, resetForm,
                }) => (
                  <Form>
                    <div className={classes.header}>
                      <div>
                        <div className={classes.headerTitle}>
                          <FormattedMessage id="NEW_TASK" />
                        </div>
                      </div>
                      <div className={classes.headerinside}>
                        <div className={classes.inputGroup}>
                          <TaskTypeButton
                            variant="contained"
                            size="medium"
                            taskType={taskType}
                            thisButton={1}
                            onClick={() => {
                              if (taskType !== 1) {
                                setTaskType(1);
                              }
                            }}
                          >
                            <FormattedMessage id="SEND_AS_TASK" />
                          </TaskTypeButton>
                        </div>
                        <div className={classes.inputGroup}>
                          <TaskTypeButton
                            variant="contained"
                            size="medium"
                            taskType={taskType}
                            thisButton={2}
                            onClick={() => {
                              if (taskType !== 2) {
                                setTaskType(2);

                                setFieldValue('forActionList', []);
                                setFieldValue('responseRequired', false);
                                setFieldValue('dueDate', null);
                              }
                            }}
                          >
                            <FormattedMessage id="SEND_AS_RESPONSE" />
                          </TaskTypeButton>
                        </div>
                      </div>
                      <div>
                        <IoMdClose
                          size={24}
                          color="rgba(0, 0, 0, 1.0)"
                          onClick={() => {
                            resetForm();
                            handleClose();
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <Divider />
                    <Paper className={classes.body}>
                      {taskType === 1 ? (
                        <div className={classes.bodyinside}>
                          <div className={classes.inputGroup}>
                            <InputTitle>
                              <FormattedMessage id="FOR_ACTION" />
                            </InputTitle>
                            <Autocomplete
                              multiple
                              id="for_action"
                              size="small"
                              options={optionList.filter((user) => {
                                if (user.role === '1') return false;
                                if (values.forInfoList && values.forActionList) {
                                  if (user.type === 'USER') {
                                    if (
                                      values.forInfoList.findIndex(
                                        (infoUser) => infoUser.id === user.id,
                                      ) === -1
                                      && values.forActionList.findIndex(
                                        (actionUser) => actionUser.id === user.id,
                                      ) === -1
                                    ) { return true; }
                                    return false;
                                  }
                                  if (user.type === 'COMPANY') {
                                    if (
                                      values.forInfoList.filter(
                                        (element) => element.company_id === user.cId,
                                      ).length
                                      + values.forActionList.filter(
                                        (element) => element.company_id === user.cId,
                                      ).length
                                      === user.range
                                    ) { return false; }
                                    return true;
                                  }
                                  if (user.type === 'GROUP') {
                                    if (
                                      values.forInfoList.filter(
                                        (element) => element.groups.findIndex(
                                          (group) => group.group_id === user.gId,
                                        ) > -1,
                                      ).length
                                      + values.forActionList.filter(
                                        (element) => element.groups.findIndex(
                                          (group) => group.group_id === user.gId,
                                        ) > -1,
                                      ).length
                                      === user.range
                                    ) { return false; }
                                    return true;
                                  }
                                  return false;
                                }
                                return false;
                              })}
                              value={values.forActionList}
                              getOptionSelected={(option, value) => option.type === 'USER' && option.id === value.id
                              }
                              getOptionLabel={(option) => `${option.name}`}
                              groupBy={(option) => option.type}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <ListSubheader
                    className={classes.groupLabel}
                    component="div"
                  >
                    {params.group === 'COMPANY'
                                      ? intl.formatMessage({
                                        id: 'ORGANIZATIONS',
                                      })
                                      : ''}
                    {params.group === 'GROUP'
                                      ? intl.formatMessage({ id: 'GROUPS' })
                                      : ''}
                    {params.group === 'USER'
                                      ? intl.formatMessage({ id: 'PERSONS' })
                                      : ''}
                  </ListSubheader>
                                  <ul className={classes.groupUl}>
                    {params.children}
                  </ul>
                                </li>
                              )}
                              onChange={(event, value, reason) => {
                                let newValue = [...value];
                                if (reason === 'select-option') {
                                  if (
                                    newValue[newValue.length - 1].type
                                    === 'COMPANY'
                                  ) {
                                    const poppedCompany = newValue.pop();

                                    const allUsers = users.filter((user) => {
                                      if (
                                        user.company_id === poppedCompany.cId
                                        && Number(user?.role) > 1
                                      ) {
                                        if (
                                          values.forInfoList.findIndex(
                                            (infoUser) => infoUser.id === user.id,
                                          ) === -1
                                          && values.forActionList.findIndex(
                                            (actionUser) => actionUser.id === user.id,
                                          ) === -1
                                        ) { return true; }
                                        return false;
                                      }
                                      return false;
                                    });

                                    newValue = newValue.concat(allUsers);
                                  } else if (
                                    newValue[newValue.length - 1].type === 'GROUP'
                                  ) {
                                    const poppedGroup = newValue.pop();

                                    const allUsers = users
                                      .filter((u) => Number(u?.role) > 1)
                                      .filter((user) => {
                                        if (
                                          user.groups.findIndex(
                                            (group) => group.group_id === poppedGroup.gId,
                                          ) > -1
                                        ) {
                                          if (
                                            values.forInfoList.findIndex(
                                              (infoUser) => infoUser.id === user.id,
                                            ) === -1
                                            && values.forActionList.findIndex(
                                              (actionUser) => actionUser.id === user.id,
                                            ) === -1
                                          ) { return true; }
                                          return false;
                                        }
                                        return false;
                                      });

                                    newValue = newValue.concat(allUsers);
                                  }
                                }
                                setFieldValue('forActionList', newValue);
                              }}
                              filterOptions={(option, a) => {
                                const filtered = option.filter((o) => {
                                  const inputText = a.inputValue
                                    .toLowerCase()
                                    .split(' ');
                                  return inputText.every(
                                    (text) => (o.type === 'USER'
                                        && o.companyName
                                          .toLowerCase()
                                          .indexOf(text) > -1)
                                      || a
                                        .getOptionLabel(o)
                                        .toLowerCase()
                                        .indexOf(text) > -1,
                                  );
                                });

                                return filtered;
                              }}
                              renderTags={(val, getProps) => val.map((x, i) => (
                                <Chip
                                  key={JSON.stringify(x)}
                                  variant="default"
                                  deleteIcon={<Cancel style={{ color: '#fff' }} />}
                                  style={{ backgroundColor: '#585368', color: '#fff', borderRadius: 6 }}
                                  label={`${x.name}, ${x.companyShortName}`}
                                  {...getProps({ i })}
                                />
                              ))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={intl.formatMessage({
                    id: 'USER_PLACEHOLDER',
                  })}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className={classes.bodyinside}>
                        <div className={classes.inputGroup}>
                          <InputTitle>
                            <FormattedMessage id="FOR_INFO" />
                          </InputTitle>
                          <Autocomplete
                            multiple
                            id="for_info"
                            size="small"
                            options={optionList.filter((user) => {
                              if (user.role === '1') return false;
                              if (values.forInfoList && values.forActionList) {
                                if (user.type === 'USER') {
                                  if (
                                    values.forInfoList.findIndex(
                                      (infoUser) => infoUser.id === user.id,
                                    ) === -1
                                    && values.forActionList.findIndex(
                                      (actionUser) => actionUser.id === user.id,
                                    ) === -1
                                  ) { return true; }
                                  return false;
                                }
                                if (user.type === 'COMPANY') {
                                  if (
                                    values.forInfoList.filter(
                                      (element) => element.company_id === user.cId,
                                    ).length
                                    + values.forActionList.filter(
                                      (element) => element.company_id === user.cId,
                                    ).length
                                    === user.range
                                  ) { return false; }
                                  return true;
                                }
                                if (user.type === 'GROUP') {
                                  if (
                                    values.forInfoList.filter(
                                      (element) => element.groups.findIndex(
                                        (group) => group.group_id === user.gId,
                                      ) > -1,
                                    ).length
                                    + values.forActionList.filter(
                                      (element) => element.groups.findIndex(
                                        (group) => group.group_id === user.gId,
                                      ) > -1,
                                    ).length
                                    === user.range
                                  ) { return false; }
                                  return true;
                                }
                                return false;
                              }
                              return false;
                            })}
                            value={values.forInfoList}
                            getOptionSelected={(option, value) => option.type === 'USER' && option.id === value.id
                            }
                            getOptionLabel={(option) => `${option.name}`}
                            groupBy={(option) => option.type}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <ListSubheader
                                  className={classes.groupLabel}
                                  component="div"
                                >
                                  {params.group === 'COMPANY'
                    ? intl.formatMessage({ id: 'ORGANIZATIONS' })
                    : ''}
                                  {params.group === 'GROUP'
                    ? intl.formatMessage({ id: 'GROUPS' })
                    : ''}
                                  {params.group === 'USER'
                    ? intl.formatMessage({ id: 'PERSONS' })
                    : ''}
                                </ListSubheader>
                                <ul className={classes.groupUl}>
                                  {params.children}
                                </ul>
                              </li>
                            )}
                            onChange={(event, value, reason) => {
                              let newValue = [...value];
                              if (reason === 'select-option') {
                                if (
                                  newValue[newValue.length - 1].type === 'COMPANY'
                                ) {
                                  const poppedCompany = newValue.pop();

                                  const allUsers = users.filter((user) => {
                                    if (user.company_id === poppedCompany.cId) {
                                      if (
                                        values.forInfoList.findIndex(
                                          (infoUser) => infoUser.id === user.id,
                                        ) === -1
                                        && values.forActionList.findIndex(
                                          (actionUser) => actionUser.id === user.id,
                                        ) === -1
                                      ) { return true; }
                                      return false;
                                    }
                                    return false;
                                  });

                                  newValue = newValue.concat(allUsers);
                                } else if (
                                  newValue[newValue.length - 1].type === 'GROUP'
                                ) {
                                  const poppedGroup = newValue.pop();

                                  const allUsers = users.filter((user) => {
                                    if (
                                      user.groups.findIndex(
                                        (group) => group.group_id === poppedGroup.gId,
                                      ) > -1
                                    ) {
                                      if (
                                        values.forInfoList.findIndex(
                                          (infoUser) => infoUser.id === user.id,
                                        ) === -1
                                        && values.forActionList.findIndex(
                                          (actionUser) => actionUser.id === user.id,
                                        ) === -1
                                      ) { return true; }
                                      return false;
                                    }
                                    return false;
                                  });

                                  newValue = newValue.concat(allUsers);
                                }
                              }
                              setFieldValue('forInfoList', newValue);
                            }}
                            filterOptions={(option, a) => {
                              const filtered = option.filter((o) => {
                                const inputText = a.inputValue
                                  .toLowerCase()
                                  .split(' ');
                                return inputText.every(
                                  (text) => (o.type === 'USER'
                                      && o.companyName.toLowerCase().indexOf(text)
                                      > -1)
                                    || a
                                      .getOptionLabel(o)
                                      .toLowerCase()
                                      .indexOf(text) > -1,
                                );
                              });

                              return filtered;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={intl.formatMessage({
                                  id: 'USER_PLACEHOLDER',
                                })}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            )}
                            renderTags={(val, getProps) => val.map((x, i) => (
                              <Chip
                                key={JSON.stringify(x)}
                                variant="default"
                                deleteIcon={<Cancel style={{ color: '#fff' }} />}
                                style={{ backgroundColor: '#585368', color: '#fff', borderRadius: 6 }}
                                label={`${x.name}, ${x.companyShortName}`}
                                {...getProps({ i })}
                              />
                            ))}
                          />
                        </div>
                      </div>
                      <div className={classes.bodyinside}>
                        <div className={classes.inputGroup}>
                          <InputTitle>
                            <FormattedMessage id="MESSAGE" />
                          </InputTitle>
                          <TextField
                            id="message"
                            name="message"
                            onChange={handleChange}
                            value={values.message}
                            placeholder={intl.formatMessage({
                              id: 'MESSAGE_DESC',
                            })}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                          />
                        </div>
                      </div>
                      {taskType === 1 ? (
                        <div className={classes.bodyinside}>
                          <div className={classes.checkGroup}>
                            <label style={{ marginRight: '16px' }}>
                              <Checkbox
                                checkedColor="#fff"
                                color="#fff"
                                className="CheckBox"
                                checked={values.responseRequired}
                                onChange={() => {
                                  setFieldValue(
                                    'responseRequired',
                                    !values.responseRequired,
                                  );
                                }}
                              />
                              <span
                                style={{
                                  height: '24px',
                                  marginLeft: 8,
                                  verticalAlign: 'middle',
                                  cursor: 'pointer',
                                  fontFamily:
                                    'Helvetica, Helvetica, Arial, serif',
                                  fontSize: '16.0px',
                                  color: 'rgba(0, 0, 0, 0.5)',
                                  lineHeight: '19.0px',
                                }}
                              >
                                <FormattedMessage id="RESPONSE_REQUIRED" />
                              </span>
                            </label>
                          </div>
                          {values.responseRequired ? (
                            <div className={classes.dateGroup}>
                              <InputTitle>
                                <FormattedMessage id="DUE_DATE" />
                              </InputTitle>
                              <DateInput
                                locale="tr"
                                disabled={!values.responseRequired}
                                selected={values.dueDate}
                                value={values.dueDate}
                                onChange={(date) => setFieldValue('dueDate', date)
                                }
                                showTimeSelect={false}
                                dropdownMode="select"
                                timeCaption="Saat"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </Paper>
                    <div className={classes.buttonGroup}>
                      <CancelButton
                        variant="contained"
                        size="medium"
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                      >
                        <FormattedMessage id="CANCEL" />
                      </CancelButton>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {!sent ? (
                          <SaveButton
                            variant="contained"
                            size="medium"
                            type="submit"
                          >
                            <FormattedMessage id="SEND" />
                          </SaveButton>
                        ) : (
                          <ProgressCircle
                            height="15vh"
                            size={30}
                            overflow="none"
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <ProgressCircle />
            )}
        </Box>
      </Fade>
    </Modal>
  );
});

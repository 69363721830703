import APIHelper from '../../helpers/APIHelper';

/** *
 * @param proje_id
 * @returns {Promise<any>}
 */
export async function getSiteSetsFunction(proje_id) {
  const response = await APIHelper().get(
    `site_sets/list?project_id=${proje_id}`,
  );

  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }

  return response.site_sets;
}

export async function searchSiteSetsFunction(search) {
  const response = await APIHelper().post('site_sets/search', null, { search });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response.siteSets;
}

export async function findByIdSiteSetssFunction(site_set_id) {
  const response = await APIHelper().post('site_sets/find', null, {
    site_set_id,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response.site_set;
}

export async function addSiteSetFunction(siteSetObj) {
  const response = await APIHelper().post('site_sets/insert', null, {
    ...siteSetObj,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response.site_set;
}

export async function updateSiteSetFunction(siteSetObj) {
  const response = await APIHelper().post('site_sets/update', null, {
    ...siteSetObj,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response;
}

export async function deleteSiteSetFunction(site_set_id) {
  const response = await APIHelper().post('site_sets/delete', null, {
    site_set_id,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response.status;
}

export async function addSiteSetDetailFunction(siteSetDetailObj) {
  const response = await APIHelper().post('site_sets/detail/insert', null, {
    ...siteSetDetailObj,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response.site_set_details;
}

export async function deleteSiteSetDetailFunction(site_set_detail_id) {
  const response = await APIHelper().post('site_sets/detail/delete', null, {
    site_set_detail_id,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response;
}
export async function updateSiteSetDetailFunction(detail_id, name) {
  const response = await APIHelper().post('site_sets/detail/update', null, {
    detail_id,
    name,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response;
}
export async function updateSiteSetTitleFunction(set_id, set_name) {
  const response = await APIHelper().post('site_sets/update', null, {
    set_id,
    set_name,
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  return response;
}

import React from 'react';
import {
  Page, Text, View, Document, Image,
} from '@react-pdf/renderer';

export default ({ data }) => (
  <>
    <Document>
      <Page size="A4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <View style={{
          width: 200, height: 200, fontSize: 24, fontWeight: 'bold',
        }}
        >
          { data && data.set_name && <Text>{data.set_name}</Text>}
          {data && <Image source={document.getElementById(`qr-${data.id}`).toDataURL('image/png')} cache={false} allowDangerousPaths />}
          { data && data.site_set_details && data.site_set_details.map((x) => <Text style={{ textAlign: 'center', marginTop: 10 }}>{x.name}</Text>)}
        </View>
      </Page>
    </Document>
  </>
);

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import moment from 'moment';

import { findByIdSiteSetssFunction } from 'api/sets/siteSets';
import { findByIdStatusSetssFunction } from 'api/sets/statusSets';
import { findByIdRevisionSetssFunction } from 'api/sets/revisionSets';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  gridTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: '19.0px',
  },
  gridTextColored: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgba(0, 145, 255, 1.0)',
    lineHeight: '22.0px',
  },
  gridText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgba(89, 84, 105, 1.0)',
    lineHeight: '22.0px',
  },
}));

const GridText = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.sideColor ? props.sideColor : 'rgba(89, 84, 105, 1.0)')};
  line-height: 22.0px;
`;

export default (props) => {
  const classes = useStyles();
  const { template } = props;
  const [siteSet, setSiteSet] = useState();
  const [statusSet, setStatusSet] = useState();
  const [revisionSet, setRevisionaSet] = useState();

  useEffect(() => {
    if (!!template) {
      findByIdSiteSetssFunction(template.site_set_id)
        .then((res) => setSiteSet(res[0]));
      findByIdStatusSetssFunction(template.status_set_id)
        .then((res) => setStatusSet(res[0]));
      findByIdRevisionSetssFunction(template.revision_set_id)
        .then((res) => setRevisionaSet(res[0]));
    }
  }, [template]);

  let statusName;
  let sideColor;

  switch (template.status) {
    case 1:
      sideColor = '#1c97fa';
      statusName = 'DRAFT';
      break;
    case 2:
      sideColor = '#fa6300';
      statusName = 'INACTIVE';
      break;
    default:
      sideColor = '#68d407';
      statusName = 'PUBLISHED';
  }

  if (!template) { return null; }

  return (
    <Grid container spacing={3} style={{ textAlign: 'left' }}>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="STATUS" /></div>
          <GridText sideColor={sideColor}>{statusName}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="CREATED_AT" /></div>
          <GridText>{moment(template.created_at, moment.DATETIME_LOCAL_MS).format('DD.MM.YYYY HH:mm')}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="VERSION" /></div>
          <GridText>{template.version}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="CREATED_BY" /></div>
          <GridText>{template.created_by_name}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="ID_NUMBER" /></div>
          <GridText>{template.id_number}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="UPDATED_AT" /></div>
          <GridText>{moment(template.uploaded_at, moment.DATETIME_LOCAL_MS).format('DD.MM.YYYY HH:mm')}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="FORM_NAME" /></div>
          <GridText>
            {template.template_name}
          </GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="UPDATED_BY" /></div>
          <GridText>{template.updated_by_name}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="SITE_SET" /></div>
          <GridText>
            {siteSet && siteSet.set_name}
          </GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="SITE_LEVEL" /></div>
          <GridText><FormattedMessage id="LEVEL" /> {template.site_level}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="STATUS_SET" /></div>
          <GridText>
            {statusSet && statusSet.statu_name}
          </GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="REVISION_SET" /></div>
          <GridText>
            {revisionSet && revisionSet.revision_name}
          </GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="PREFIX" /></div>
          <GridText>{template.prefix}</GridText>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={`${classes.flexRower} ${classes.leftAlign}`}>
          <div className={classes.gridTitle}><FormattedMessage id="COUNTER_FORMAT" /></div>
          <GridText>{template.counter_format}</GridText>
        </div>
      </Grid>
    </Grid>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import {
  getRevisionSetsFunction,
  addRevisionSetFunction,
  updateRevisionSetFunction,
  deleteRevisionSetFunction,
  addRevisionSetDetailFunction,
  deleteRevisionSetDetailFunction,
  updateRevisionSetDetailFunction,
  updateRevisionSetTitleFunction,
} from 'api/sets/revisionSets';

const initialState = {
  loading: {
    getRevisionSets: false,
    addRevisionSet: false,
    updateRevisionSet: false,
    deleteRevisionSet: false,
    addRevisionSetDetail: false,
    deleteRevisionSetDetail: false,
    updateRevisionSetDetail: false,
  },
  hasErrors: {
    getRevisionSets: false,
    addRevisionSet: false,
    updateRevisionSet: false,
    deleteRevisionSet: false,
    addRevisionSetDetail: false,
    deleteRevisionSetDetail: false,
    updateRevisionSetDetail: false,
  },
  revisionSets: [],
};

const revisionSetsSlice = createSlice({
  name: 'revisionSets',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getRevisionSetsSuccess: (state, { payload }) => {
      state.revisionSets = payload;
      state.loading.getRevisionSets = false;
      state.hasErrors.getRevisionSets = false;
    },
    addRevisionSetSuccess: (state, { payload }) => {
      state.revisionSets.unshift(payload);
      state.loading.addRevisionSet = false;
      state.hasErrors.addRevisionSet = false;
    },
    updateRevisionSetSuccess: (state, { payload }) => {
      state.revisionSets = state.revisionSets.map((revisionSet) => {
        if (revisionSet.id === payload.id) return payload;
        return revisionSet;
      });
      state.loading.updateRevisionSet = false;
      state.hasErrors.updateRevisionSet = false;
    },
    deleteRevisionSetSuccess: (state, { payload }) => {
      state.revisionSets = state.revisionSets.filter(
        (revisionSet) => revisionSet.id !== payload,
      );
      state.loading.deleteRevisionSet = false;
      state.hasErrors.deleteRevisionSet = false;
    },
    addRevisionSet_Detail_Success: (state, { payload }) => {
      state.revisionSets.forEach((revisionSet) => {
        if (revisionSet.id == payload.revision_id) {
          revisionSet.revision_set_details.push(payload);
        }
      });
      state.loading.addRevisionSetDetail = false;
      state.hasErrors.addRevisionSetDetail = false;
    },
    deleteRevisionSet_Detail_Success: (state, { payload }) => {
      state.revisionSets.forEach((revisionSet) => {
        if (
          revisionSet.revision_set_details.some(
            (detail) => detail.id == payload,
          )
        ) {
          revisionSet.revision_set_details = revisionSet.revision_set_details.filter(
            (detail) => detail.id != payload,
          );
        }
      });
      state.loading.deleteRevisionSetDetail = false;
      state.hasErrors.deleteRevisionSetDetail = false;
    },
    updateRevisionSet_Detail_Success: (state, { payload }) => {
      state.revisionSets.forEach((revisionSet) => {
        if (
          revisionSet.revision_set_details.some(
            (detail) => detail.id == payload.detail_id,
          )
        ) {
          revisionSet.revision_set_details = revisionSet.revision_set_details.map(
            (detail) => {
              if (detail.id === payload.detail_id) { return { ...detail, name: payload.name }; }
              return detail;
            },
          );
        }
      });
      state.loading.updateRevisionSetDetail = false;
      state.hasErrors.updateRevisionSetDetail = false;
    },
    revisionSet_Title_Update_Success: (
      state,
      { payload: { set_id, name } },
    ) => {
      state.revisionSets.forEach((revisionSet) => {
        if (revisionSet.id === set_id) {
          revisionSet.revision_name = name;
        }
      });
    },
  },
});

export const {
  asyncStart,
  asyncFailure,
  getRevisionSetsSuccess,
  addRevisionSetSuccess,
  updateRevisionSetSuccess,
  deleteRevisionSetSuccess,
  addRevisionSet_Detail_Success,
  deleteRevisionSet_Detail_Success,
  updateRevisionSet_Detail_Success,
  revisionSet_Title_Update_Success,
} = revisionSetsSlice.actions;

export default revisionSetsSlice.reducer;

export const revisionSetsSelector = (state) => state.revisionSets;

export const fetchRevisionSets = (proje_id) => async (dispatch) => {
  dispatch(asyncStart('getRevisionSets'));

  try {
    const _revisionSets = await getRevisionSetsFunction(proje_id);
    dispatch(getRevisionSetsSuccess(_revisionSets));
  } catch (error) {
    dispatch(asyncFailure('getRevisionSets'));
  }
};

export const addRevisionSet = (revisionSetObj) => async (dispatch) => {
  dispatch(asyncStart('addRevisionSet'));
  try {
    const newRevisionSet = await addRevisionSetFunction(revisionSetObj);
    dispatch(
      addRevisionSetSuccess({ ...newRevisionSet, revision_set_details: [] }),
    );
  } catch (error) {
    dispatch(asyncFailure('addRevisionSet'));
  }
};

export const updateRevisionSet = (revisionSetObj) => async (dispatch) => {
  dispatch(asyncStart('updateRevisionSet'));
  try {
    await updateRevisionSetFunction(revisionSetObj);
    dispatch(updateRevisionSetSuccess(revisionSetObj));
  } catch (error) {
    dispatch(asyncFailure('updateRevisionSet'));
  }
};

export const deleteRevisionSet = (revisionSet_id) => async (dispatch) => {
  dispatch(asyncStart('deleteRevisionSet'));
  try {
    await deleteRevisionSetFunction(revisionSet_id);
    dispatch(deleteRevisionSetSuccess(revisionSet_id));
  } catch (error) {
    dispatch(asyncFailure('deleteRevisionSet'));
  }
};

export const addRevisionSetDetail = (revision_set_detail_obj) => {
  revision_set_detail_obj.revision_id = revision_set_detail_obj.set_id;
  delete revision_set_detail_obj.set_id;
  return async (dispatch) => {
    dispatch(asyncStart('addRevisionSetDetail'));
    try {
      const new_detail_obj = await addRevisionSetDetailFunction(
        revision_set_detail_obj,
      );
      dispatch(addRevisionSet_Detail_Success(new_detail_obj));
    } catch (error) {
      dispatch(asyncFailure('addRevisionSetDetail'));
    }
  };
};

export const deleteRevisionSetDetail = (revision_set_detail_id) => async (
  dispatch,
) => {
  dispatch(asyncStart('deleteRevisionSetDetail'));
  try {
    await deleteRevisionSetDetailFunction(revision_set_detail_id);
    dispatch(deleteRevisionSet_Detail_Success(revision_set_detail_id));
  } catch (error) {
    dispatch(asyncFailure('deleteRevisionSetDetail'));
  }
};

export const updateRevisionSetDetail = (detail_id, name) => async (
  dispatch,
) => {
  dispatch(asyncStart('updateRevisionSetDetail'));
  try {
    await updateRevisionSetDetailFunction(detail_id, name);
    dispatch(updateRevisionSet_Detail_Success({ detail_id, name }));
  } catch (error) {
    dispatch(asyncFailure('updateRevisionSetDetail'));
  }
};

export const updateRevisionSetTitle = (set_id, name) => async (dispatch) => {
  try {
    await updateRevisionSetTitleFunction(set_id, name);
    dispatch(revisionSet_Title_Update_Success({ set_id, name }));
  } catch (error) {
  }
};

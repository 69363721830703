/**
 * @function CumulativeTotal Count all items of array when passed as array
 * @param {Array} array
 */
const cumulativeTotal = (array) =>
  array
    .filter((item) => !!item)
    .map((item, index) => {
      return array
        .slice(0, index)
        .reduce((result, current) => result + current, 0);
    });

export default cumulativeTotal;

import APIHelper from '../helpers/APIHelper';

export async function getGroupsFunction() {
  const response = await APIHelper().get('groups/list');
  if (response.error) {
    throw new Error(response.error);
  }
  return response.groups;
}

export async function searchGroupsFunction(search) {
  const response = await APIHelper().post('groups/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.groups;
}

export async function findByIdGroupsFunction(groupId) {
  const response = await APIHelper().post('groups/find', null, {
    group_id: groupId
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.group;
}

export async function addGroupFunction(groupObj) {
  const response = await APIHelper().post('groups/insert', null, {
    ...groupObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.revision_set;
}

export async function updateGroupFunction(groupObj) {
  const response = await APIHelper().post('groups/update', null, {
    ...groupObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function deleteGroupFunction(groupId) {
  const response = await APIHelper().post('groups/delete', null, {
    group_id: groupId
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function findGroupsByCompanyId(companyId) {
  const response = await APIHelper().post('groups/find_by_company', null, {
    company_id: companyId
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.group;
}

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GiPositionMarker } from 'react-icons/gi';

// Stiller
const styles = {
  contain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
};

/***
 * @class Filters
 */
class Filters extends React.Component {
  /**
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      getData: false,
      parents: [],
      childs: [],
      locations: [],
      tempLocations: []
    };
  }

  /***
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.showFilter !== this.state.showFilter) {
      this.setState({ showFilter: nextProps.showFilter });
    }
  }

  /**
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.getSiteStes();
  }

  /***
   * @returns {Promise<void>}
   */
  getSiteStes = async () => {
    const locations = this.props.siteSets;
    let tempLocations = [];
    for (const [index, item] of Object.entries(locations || {})) {
      tempLocations[item.id] = item.site_set_details;
    }

    this.setState({
      locations: locations,
      tempLocations: tempLocations,
      getData: true
    });
  };

  /***
   * @param event
   */
  selectMainLoc = (event) => {
    let arrayKey = event.target.value;
    let arrays = this.state.tempLocations[arrayKey];
    let parents = [];
    let childs = [];

    if (typeof arrays != 'undefined') {
      for (const [index, item] of Object.entries(arrays)) {
        if (item.parent_id == 0) {
          parents.push({
            id: item.id,
            name: item.name
          });
        } else {
          childs.push({
            id: item.id,
            name: item.name,
            parent_id: item.parent_id
          });
        }
      }
    }

    this.setState({ parents: parents, childs: childs });
  };

  /***
   * @param event
   */
  selectChild = (event) => {
    let childs = this.state.childs;
    let arrayKey = event.target.value;
    let childItems = [];

    if (typeof arrays != 'undefined') {
      for (const [index, item] of Object.entries(childs)) {
        if (arrayKey == item.parent_id) {
          childItems.push({
            id: item.id,
            name: item.name
          });
        }
      }
    }

  };

  /* /!***
   * @param event
   *!/
  parentSelectDom = (event) => {
    return (
      <select name='locations' className={'form-control'} id='locations' onChange={this.selectMainLoc} >
        <option selected disabled>Lütfen seçiniz.</option>
        {this.state.locations && this.state.locations.map((template) => (
          <option key={`options-${template.id}`} value={template.id}>
            {template.set_name}
          </option>
        ))}
      </select>
    )
  }*/

  /***
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        {this.state.getData ? (
          <Dialog
            open={this.state.showFilter}
            onClose={() => {
              this.props.onCallbackFiltersShowModal({
                showFilter: false
              });
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            scroll="body"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              <div style={styles.contain}>
                <span>
                  <FormattedMessage id="SELECT_FILTERS" />{' '}
                </span>
                <IconButton
                  onClick={() => {
                    this.props.onCallbackFiltersShowModal({
                      showFilter: false
                    });
                  }}
                >
                  <AiOutlineCloseCircle />
                </IconButton>
              </div>
            </DialogTitle>

            <DialogContent dividers>
              <GiPositionMarker
                color="rgba(250, 100, 0, 1)"
                fontSize={14}
                style={{ marginRight: '5px' }}
              />
              <span style={{ fontWeight: 600, marginRight: '10px' }}>
                <FormattedMessage id="LOCATION" />
              </span>

              <select
                name="locations"
                className={'form-control'}
                id="locations"
                onChange={this.selectMainLoc}
              >
                <option selected disabled>
                  Lütfen seçiniz.
                </option>
                {this.state.locations &&
                  this.state.locations.map((template) => (
                    <option key={`options-${template.id}`} value={template.id}>
                      {template.set_name}
                    </option>
                  ))}
              </select>

              {this.state.parents.length > 0 ? (
                <div>
                  <span style={{ fontSize: '15px' }}> > </span>

                  <select
                    className={'form-control'}
                    onChange={this.selectChild}
                  >
                    <option selected disabled>
                      Lütfen seçiniz.
                    </option>
                    {this.state.parents.map((x) => (
                      <option key={`options-${x.id}`} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </DialogContent>

            <DialogActions>
              <div style={styles.contain}>
                <Button
                  onClick={() => {
                    this.props.onCallbackFiltersShowModal({
                      showFilter: false
                    });
                  }}
                  variant="contained"
                  style={{ margin: '5px 15px' }}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={() => {}}
                    color="primary"
                    variant="contained"
                    style={{ margin: '5px 15px' }}
                  >
                    <FormattedMessage id="APPLY" />
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Filters;

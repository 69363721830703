import APIHelper from '../helpers/APIHelper';

export async function getProjectsFunction() {
  const response = await APIHelper().get('projects/list');
  if (response.error) {
    throw new Error(response.error);
  }
  return response.projects;
}

export async function searchProjectsFunction(search) {
  const response = await APIHelper().post('projects/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.projects;
}

export async function findByIdProjectssFunction(project_id) {
  const response = await APIHelper().post('projects/find', null, {
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.projects;
}

export async function addProjectFunction(projectObj) {
  const response = await APIHelper().post('projects/insert', null, {
    ...projectObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.project;
}

export async function updateProjectFunction(projectObj) {
  const response = await APIHelper().post('projects/update', null, {
    ...projectObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function deleteProjectFunction(project_id) {
  const response = await APIHelper().post('projects/delete', null, {
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

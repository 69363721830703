import APIHelper from '../helpers/APIHelper';

export async function getTemplatesFunction() {
  const response = await APIHelper().get('templates/templatelist');
  if (response.error) {
    throw new Error(response.error);
  }

  let newTemplates = [];

  if (response.templates) {
    const splitToDrafts = async () =>
      Promise.all(
        response.templates.reverse().map((temp) => {
          const {
            id: tempId,
            template_name,
            created_at,
            updated_at,
            status,
            original_template_id: orginal,
            version,
            ...props
          } = temp;

          const filteredList = newTemplates.filter(
            (filtered) => filtered.original_template_id === orginal
          );
          const unfilteredList = newTemplates.filter(
            (filtered) => filtered.original_template_id !== orginal
          );
          if (filteredList.length === 0) {
            newTemplates.unshift({
              id: tempId,
              original_template_id: orginal,
              template_name,
              created_at,
              updated_at,
              status,
              version: 1,
              drafts: [temp]
            });
          } else {
            newTemplates = [
              ...unfilteredList,
              {
                id: tempId,
                template_name,
                original_template_id: orginal,
                updated_at,
                created_at,
                status,
                version,
                drafts: [temp, ...filteredList[0].drafts]
              }
            ];
          }
          return temp;
        })
      );

    return splitToDrafts().then(() => newTemplates);
  }

  return newTemplates;
}

export async function searchTemplatesFunction(search) {
  const response = await APIHelper().post('templates/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }

  let newTemplates = [];

  if (response.templates) {
    const splitToDrafts = async () =>
      Promise.all(
        response.templates.map((temp) => {
          const {
            id: tempId,
            template_name,
            created_at,
            updated_at,
            status,
            ...props
          } = temp;
          const filteredList = newTemplates.filter(
            (filtered) =>
              filtered.template_name.toLowerCase() ===
              template_name.toLowerCase()
          );
          const unfilteredList = newTemplates.filter(
            (filtered) =>
              filtered.template_name.toLowerCase() !==
              template_name.toLowerCase()
          );
          if (filteredList.length === 0) {
            newTemplates.unshift({
              id: tempId,
              template_name,
              created_at,
              updated_at,
              status,
              version: 1,
              drafts: [{ ...props, created_at, updated_at }]
            });
          } else {
            newTemplates = [
              ...unfilteredList,
              {
                id: tempId,
                template_name,
                updated_at: filteredList[0].updated_at,
                created_at,
                status: filteredList[0].status,
                version: filteredList[0].version + 1,
                drafts: [
                  { ...props, created_at, updated_at },
                  ...filteredList[0].drafts
                ]
              }
            ];
          }
          return temp;
        })
      );

    return splitToDrafts().then(() => newTemplates);
  }

  return newTemplates;
}

export async function findByIdTemplatessFunction(template_id) {
  const response = await APIHelper().post('templates/find', null, {
    template_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template[0];
}

export async function addTemplateFunction(templateObj) {
  const { id, ...finalObj } = templateObj;
  const response = await APIHelper().post('templates/insert', null, {
    ...finalObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template;
}

export async function updateTemplateFunction(templateObj) {
  const response = await APIHelper().post('templates/update', null, {
    ...templateObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template;
}

export async function deleteTemplateFunction(template_id) {
  const response = await APIHelper().post('templates/delete', null, {
    template_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.status;
}

export async function getGroupsByTemplateIdFuction(template_id) {
  const response = await APIHelper().post('templates/get_group', null, {
    template_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.templatgroups;
}

export async function addGroupsWithTemplateIdFuction(template_id, group_id) {
  const response = await APIHelper().post('templates/add_group', null, {
    template_id,
    group_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template_groups;
}

export async function deleteGroupsWithTemplateIdFuction(template_id, group_id) {
  const response = await APIHelper().post('templates/delete_group', null, {
    template_id,
    group_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template_groups;
}

export async function findByProjectIdTemplatesFunction(project_id) {
  const response = await APIHelper().post('templates/find_by_project', null, {
    project_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.templates;
}
export async function findByOrginalIdTemplatesFunction(
  project_id,
  original_template_id
) {
  const response = await APIHelper().post(
    'templates/find_by_original_template_id',
    null,
    { project_id, original_template_id }
  );
  if (response.error) {
    throw new Error(response.error);
  }
  return response.templates;
}

export async function findByMetadataIdTemplatesFunction(
  project_id,
  metadata_detail_id
) {
  const response = await APIHelper().post(
    'templates/find_by_metadata_detail_id',
    null,
    { project_id, metadata_detail_id }
  );
  if (response.error) {
    throw new Error(response.error);
  }

  let newTemplates = [];
  const lastVersionTemplates = [];

  if (response.templates) {
    const splitToDrafts = async () =>
      Promise.all(
        response.templates.reverse().map((temp) => {
          const {
            id: tempId,
            template_name,
            created_at,
            updated_at,
            status,
            original_template_id: orginal,
            version,
            project_id,
            ...props
          } = temp;

          const filteredList = newTemplates.filter(
            (filtered) => filtered.original_template_id === orginal
          );
          const unfilteredList = newTemplates.filter(
            (filtered) => filtered.original_template_id !== orginal
          );
          if (filteredList.length === 0) {
            newTemplates.unshift({
              id: tempId,
              original_template_id: orginal,
              template_name,
              created_at,
              updated_at,
              status,
              version: 1,
              project_id,
              drafts: [temp]
            });
          } else {
            newTemplates = [
              ...unfilteredList,
              {
                id: tempId,
                template_name,
                original_template_id: orginal,
                updated_at,
                created_at,
                status,
                version,
                project_id,
                drafts: [temp, ...filteredList[0].drafts]
              }
            ];
          }
          return temp;
        })
      );

    const checkLastVersion = async () =>
      Promise.all(
        newTemplates.map(async (temp) => {
          const allversions = await findByOrginalIdTemplatesFunction(
            temp.project_id,
            temp.original_template_id,
            1
          );
          if (allversions[0].id === temp.id) lastVersionTemplates.unshift(temp);
          return temp;
        })
      );
    await splitToDrafts();
    return checkLastVersion().then(() => lastVersionTemplates);
  }
  return newTemplates;
}

export async function getMetadatasByTemplateIdFuction(template_id) {
  const response = await APIHelper().post('templates/get_metadata', null, {
    template_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.templatemetadatas;
}

export async function addMetadataWithTemplateIdFuction(
  template_id,
  metadata_id,
  metadata_detail_id
) {
  const response = await APIHelper().post('templates/add_metadata', null, {
    template_id,
    metadata_id,
    metadata_detail_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response.template_metadatas;
}

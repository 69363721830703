import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import QRCode from 'qrcode.react';

import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiFillPlusCircle,
} from 'react-icons/ai';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import { MdRemoveCircle, MdCheck } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  updateMetadataSet,
  updateSiteSetTitle,
  updateMetadataSetTitle,
  updateRevisionSetTitle,
  updateStatusSetTitle,
} from 'slices';
import { useIntl, FormattedMessage } from 'react-intl';

import {
  Page, Text, View, Document, PDFDownloadLink,
} from '@react-pdf/renderer';
import { determineTheType, renderTree } from './helper';
import qrImage from '../../assets/qrcode.png';
import PDFDocument from './PDFDocument';

const useStyles = makeStyles(() => ({
  root: () => ({
    minHeight: 200,
    flexGrow: 1,
    maxWidth: 800,
    overflow: 'auto',
  }),
  setDiv: () => ({
    borderRadius: '8px',
    minWidth: 280,
    margin: '15px 10px',
  }),
  label: {
    fontSize: '12px !important',
    textAlign: 'left',
  },
}));

const SetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ccd0d3;
  width: 100%;
  height: 50px;
  padding: 5px 0 5px 10px;
  border-radius: 8px;
`;

const Title = styled.span`
  font-size: 18px;
  line-height: 21px;
  color: #595469;
  width: 100%;
  text-align: left;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
`;
const SetContent = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 30px;
  background: #eee;
`;

const ImportButton = styled(Button)`
  flex: 1;
`;
const BottomCheck = styled.div`
  flex: 1;
  margin-top: 10px;
`;

const QRButton = styled.img`
  height: 24px;
  width: auto;
  margin-left: 10px;
`;

const PlusButton = styled(IconButton)`
  color: blue !important;
`;

const AddLineDiv = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  padding: 0px;
  flex-direction: column;
`;

function compare_id(a, b) {
  // a should come before b in the sorted order
  if (a.id < b.id) {
    return -1;
    // a should come after b in the sorted order
  }
  if (a.id > b.id) {
    return 1;
    // and and b are the same
  }
  return 0;
}

const Set = ({
  id, title, node_list, local, status, metadata, addSetDetail, deleteSetDetail, dispatch, deleteSet, project_id, multi_select, roles, typeOfSet, set,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newRow, setNewRow] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [error, setError] = useState('');
  const [parentId, setParentId] = useState(0);
  const [editTitle, setEditTitle] = useState(false);
  const [updating, setUpdating] = useState(false);
  const classes = useStyles();

  const intl = useIntl();

  const reduxDispatch = useDispatch();

  const typeObject = determineTheType(typeOfSet);

  const handleAddLine = () => {
    if (newRow.trim() === '') {
      setError(intl.formatMessage({ id: 'REQUIRED_ROW_NAME' }));
    } else {
      dispatch(addSetDetail({ name: newRow, parent_id: parentId, set_id: id }));
      setNewRow('');
      setError('');
      setShowModal(false);
    }
  };

  const deleteDetail = (id) => {
    node_list
      .filter((node) => node.parent_id == id)
      .forEach((node) => deleteDetail(node.id));
    dispatch(deleteSetDetail(id));
  };

  const addDetail = (parent_id) => {
    setShowModal(true);
    setParentId(parent_id);
  };

  const handleDeleteSet = () => {
    dispatch(deleteSet(id));
  };

  const _node_list = node_list ? [...node_list] : [];

  const closeUpdateLine = () => {
    setNewTitle('');
    setEditTitle(false);
    setUpdating(false);
  };

  const handleUpdateLine = () => {
    if (newTitle.trim() === '') {
      // setError(intl.formatMessage({ id: 'REQUIRED_ROW_NAME' }));
    } else {
      setUpdating(true);
      switch (typeObject.currentType) {
        case 'local':
          reduxDispatch(updateSiteSetTitle(id, newTitle)).then(() => closeUpdateLine());
          break;
        case 'status':
          reduxDispatch(updateStatusSetTitle(id, newTitle)).then(() => closeUpdateLine());
          break;
        case 'metadata':
          reduxDispatch(updateMetadataSetTitle(id, newTitle)).then(() => closeUpdateLine());
          break;
        case 'revision':
          reduxDispatch(updateRevisionSetTitle(id, newTitle)).then(() => closeUpdateLine());
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={classes.setDiv}>
      <SetHeader>
        {editTitle ? (
          <>
            <TextField
              margin="dense"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              multiline
            />
            {updating ? (
              <CircularProgress size="calc(1vh + 1vw)" />
            ) : (
              <MdCheck onClick={handleUpdateLine} />
            )}
          </>
        ) : (
          <Title
            onDoubleClick={() => {
              setEditTitle(true);
              setNewTitle(title);
            }}
          >
            {title}
          </Title>
        )}
        <ButtonDiv>
          {typeObject.isLocal
            && (
            <PDFDownloadLink document={<PDFDocument data={set} />} fileName={`crane-mahal-${set.id}.pdf`}>
              {({
                blob, url, loading, error,
              }) => <QRButton src={qrImage} />}
            </PDFDownloadLink>
            )
          }
          <PlusButton onClick={() => setDeleteModal(true)}>
            <MdRemoveCircle size={30} color="#409FFF" />
            {set && <QRCode size={80} value={`http://web.crane.ist/site/${set.id}`} id={set && `qr-${set.id}`} style={{ display: 'none' }} />}
          </PlusButton>
        </ButtonDiv>
      </SetHeader>
      <SetContent>
        {/* _node_list.map((node) => `${node.id}`) */}
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<AiFillCaretDown color="#979797" />}
          defaultExpanded={[]}
          defaultExpandIcon={<AiFillCaretRight color="#979797" />}
        >
          {node_list
            && _node_list
              .sort(compare_id)
              .map((node, index) => renderTree(
                node,
                index,
                local,
                0,
                node_list,
                addDetail,
                deleteDetail,
                status,
                typeObject,
                roles,
              ))}
        </TreeView>
        <AddLineDiv>
          <AiFillPlusCircle
            size={24}
            color="blue"
            onClick={() => {
              addDetail(0);
            }}
            style={{ margin: '5px auto', cursor: 'pointer' }}
          />
          {typeObject.isLocal && (
            <ImportButton variant="contained">
              <FormattedMessage id="IMPORT_FROM_EXEL" />
            </ImportButton>
          )}
        </AddLineDiv>
        {typeObject.isStatus && (
          <BottomCheck variant="contained">
            <FormControlLabel
              classes={{ label: classes.label }}
              control={<Checkbox />}
              label={intl.formatMessage({ id: 'STATUS_CHECKBOX_TEXT' })}
            />
          </BottomCheck>
        )}
        {typeObject.isMetadata && (
          <BottomCheck variant="contained">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={multi_select == 1}
                  onChange={(e) => dispatch(
                    updateMetadataSet({
                      id,
                      metadata_id: id,
                      metadata_name: title,
                      project_id,
                      multi_select: e.target.checked ? 1 : 0,
                    }),
                  )
                  }
                />
              )}
              label={intl.formatMessage({ id: 'MULTIPLE_SELECT' })}
            />
          </BottomCheck>
        )}
      </SetContent>
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setError('');
        }}
        aria-labelledby="form-dialog-set-name"
      >
        <DialogContent>
          <TextField
            label={intl.formatMessage({ id: 'NEW_ROW_NAME' })}
            onChange={(e) => setNewRow(e.target.value)}
          />
          {error ? (
            <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddLine}
            color="primary"
            variant="contained"
            className={classes.dialogButton}
          >
            <FormattedMessage id="ADD" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="form-dialog-delete-set-item-detail"
      >
        <DialogContent>
          <FormattedMessage id="ARE_YOU_SURE_DELETE" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteModal(false)}
            color="default"
            variant="contained"
          >
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button
            onClick={() => handleDeleteSet()}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage id="DELETE" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Set;

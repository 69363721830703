import React, {
  useState, useRef, createRef, useEffect, useLayoutEffect,
} from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,
} from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { authentication } from 'helpers/authentication';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AlertDialog from 'components/Common/AlertDialog';
import craneLogo from '../../assets/header/smallLogo.png';

const Title = styled.div`
    font-size: calc( 4px + 0.5vh + 0.5vw);
    text-align: center;
`;

const Content = styled.div`
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    width: 100%;
`;

const NextButton = styled(Button)`
    font-size: calc( 2px + 0.5vh + 0.5vw);
`;

const ApplyButton = styled(Button)`
    font-size: calc( 2px + 0.5vh + 0.5vw);
`;

const QRCode = styled.div`
    height: 20vh;
    width: auto;
`;

const Input = styled(TextField)`
    height: calc( 1vh / 1vw * 3);
    width: calc( 1vh / 1vw * 2);
    margin: 10px !important;
`;

const CraneLogo = styled.img`
    height: 20vh;
    width: auto;
    margin: 2vh 2vw !important;
`;

const TwoFactor = (props) => {
  const [title, setTitle] = useState('SCAN_QR_CODE');
  const [values, setValues] = useState({});
  const [next, setNext] = useState(false);
  const [img, setImg] = useState();
  const [alertModal, setAlertModal] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const qrRef = useRef();

  const { token, activate2f } = location.state || { token: null };

  useEffect(() => {
    if (token && activate2f) {
      authentication.getQR(token).then((res) => {
        setImg(res);
        qrRef.current = (res);
      });
    }
  }, [activate2f, token]);

  // for navigate to code screen
  const handleNext = () => {
    setNext(true);
    setTitle('SCAN_QR_CODE');
  };

  // for navigate to back  qr code screen
  const handleBack = () => {
    setNext(false);
    setTitle('ENTER_THE_CODE');
  };

  // Sending authentication code
  const handleApply = () => {
    const inputArray = [0, 1, 2, 3, 4, 5];
    let code = '';
    inputArray.forEach((index) => {
      code += values[`input-element-${index}`] || '';
    });
    if (code.length !== 6) {
      setAlertModal(true);
    }
    authentication.checkQR(token, code)
      .then((res) => {
        if (res) {
          history.push('/');
        } else {
          setAlertModal(true);
        }
      });
  };

  const alertText = <FormattedMessage id="CHECK_THE_CODE" />;

  return (
    <>
      <AlertDialog
        alertText={alertText}
        open={alertModal}
        setOpen={setAlertModal}
      />
      <Dialog
        open
        aria-labelledby="two-factor-dialog-title"
        fullWidth
      >
        <DialogTitle
          id="two-factor-dialog-title"
        >
          <Title>
            <FormattedMessage id={title} />
          </Title>
        </DialogTitle>
        <DialogContent
          dividers
        >
          <Content>
            {
                        activate2f && !next
                          ? <QRCode dangerouslySetInnerHTML={{ __html: img }} />

                          : (
                            <InputContent
                              values={values}
                              setValues={setValues}
                              isItQR={activate2f}
                            />
                          )
                    }

          </Content>
        </DialogContent>
        <DialogActions>
          <Actions>
            {
                        activate2f && !next
                          ? (
                            <NextButton
                              variant="contained"
                              onClick={() => handleNext()}
                            >
                              <FormattedMessage id="NEXT" />
                            </NextButton>
                          )
                          : (
                            <>
                              <ApplyButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleApply()}
                              >
                                <FormattedMessage id="APPLY" />
                              </ApplyButton>
                              {
                                activate2f && (
                                <NextButton
                                  variant="contained"
                                  onClick={() => handleBack()}
                                >
                                  <FormattedMessage id="BACK" />
                                </NextButton>
                                )
                            }

                            </>
                          )
                    }
          </Actions>
        </DialogActions>

      </Dialog>
    </>
  );
};

export default TwoFactor;

const InputContent = ({ values, setValues }) => {
  const [focus, setFocus] = useState(0);

  const [inputRef, setInputRef] = useState([]);

  const inputArray = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    setInputRef(inputArray.map((ref, index) => (
      createRef()
    )));
  }, []);

  const inputChange = (event) => {
    const { value, name } = event.target;
    const index = Number(name.split('-').pop());

    if (value === '') {
      const _values = values;
      delete _values[name];
      inputRef[index].current.focus();
    } else {
      const _values = { ...values, [name]: value };
      setValues(_values);
      if (index < 5) inputRef[index + 1].current.focus();
    }
  };

  // clear input value when input focused
  const handeFocus = (event) => {
    const { name } = event.target;
    const index = Number(name.split('-').pop());
    if (inputRef[index].current) inputRef[index].current.value = '';
  };

  return (
    <FormContainer>
      <CraneLogo src={craneLogo} />
      <InputContainer>
        {inputArray.map((input) => (
          <Input
            name={`input-element-${input}`}
            id={`input-${input}`}
            onChange={inputChange}
            type="tel"
            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
            autoFocus={input === focus}
            variant="outlined"
            inputRef={inputRef[input]}
          />
        ))}
      </InputContainer>
    </FormContainer>

  );
};

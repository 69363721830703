import { createSlice } from '@reduxjs/toolkit';
import {

  getCompaniesFunction, addCompanyFunction, updateCompanyFunction, deleteCompanyFunction,
  addProjectToCompanyFunction, updateCompanyLogoFunction, findCompaniesByProjectIdFunc, searchCompaniesFunction, findByIdCompaniessFunction, findCompaniesByProjectIdFunction, deleteProjectToCompanyFunction,

} from 'api';
import { authentication } from 'helpers/authentication';

const initialState = {
  loading: {
    getCompanies: false,
    addCompany: false,
    updateCompany: false,
    deleteCompany: false,
    updateCompanyLogo: false,
    searchCompanies: false,
    findCompanyId: false,
  },
  hasErrors: {
    getCompanies: false,
    addCompany: false,
    updateCompany: false,
    deleteCompany: false,
    updateCompanyLogo: false,
    searchCompanies: false,
    findCompanyId: false,
  },
  companies: [],
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getCompaniesSuccess: (state, { payload }) => {
      state.companies = payload;
      state.loading.getCompanies = false;
      state.hasErrors.getCompanies = false;
    },
    searchCompaniesSuccess: (state, { payload }) => {
      state.companies = payload;
      state.loading.searchCompanies = false;
      state.hasErrors.searchCompanies = false;
    },
    addCompanySuccess: (state, { payload }) => {
      state.companies.unshift(payload);
      state.loading.addCompany = false;
      state.hasErrors.addCompany = false;
    },
    updateCompanySuccess: (state, { payload }) => {
      state.companies = state.companies.map((company) => {
        if (company.id === payload.id) { return payload; }
        return company;
      });
      state.loading.updateCompany = false;
      state.hasErrors.updateCompany = false;
    },
    deleteCompanySuccess: (state, { payload }) => {
      state.companies.filter((company) => company.id !== payload);
      state.loading.deleteCompany = false;
      state.hasErrors.deleteCompany = false;
    },

    addProjectToCompanySuccess: (state, { company_id, project_id }) => {
      state.companies.forEach((company) => {
        if (company.id == company_id) {
          company.project_id = [...company.project_id, project_id];
        }
      });
    },

    deleteProjectToCompanySuccess: (state, { company_id, project_id }) => {
      state.companies.forEach((company) => {
        if (company.id == company_id) {
          company.project_id = company.project_id.filter((project) => project !== project_id);
        }
      });
    },

    updateCompanyLogoSuccess: (state, { payload }) => {
      state.companies = state.companies.map((company) => {
        if (company.id === payload.company_id) { return { ...company, ...payload }; }
        return company;
      });
      state.loading.updateCompanyLogo = false;
      state.hasErrors.updateCompanyLogo = false;
    },

  },
});

export const {

  asyncStart, asyncFailure, getCompaniesSuccess, addCompanySuccess, updateCompanySuccess,
  deleteCompanySuccess, addProjectToCompanySuccess, deleteProjectToCompanySuccess,
  updateCompanyLogoSuccess, searchCompaniesSuccess, findCompanyByIdSuccess,

} = companiesSlice.actions;

export default companiesSlice.reducer;

export const companiesSelector = (state) => state.companies;

export const fetchCompanies = () => async (dispatch) => {
  dispatch(asyncStart('getCompanies'));

  try {
    const _companies = await getCompaniesFunction();
    dispatch(getCompaniesSuccess(_companies));
  } catch (error) {
    dispatch(asyncFailure('getCompanies'));
  }
};
export const searchCompanies = (search, project_id) => async (dispatch) => {
  dispatch(asyncStart('searchCompanies'));
  const { company_id: companyId } = authentication.userValue;
  try {
    let companies = await searchCompaniesFunction(search);

    if (project_id) {
      const project_companies = await findCompaniesByProjectIdFunc(project_id);
      companies = companies.filter((c) => project_companies.some((p) => p.company_id === c.id));
    }
    if (companyId) {
      companies = companies.filter((c) => c.id === companyId);
    }
    dispatch(searchCompaniesSuccess(companies));
  } catch (error) {
    dispatch(asyncFailure('searchCompanies'));
  }
};

export const findCompaniesByProjectId = (project_id) => async (dispatch) => {
  dispatch(asyncStart('getCompanies'));

  try {
    const _companies = await getCompaniesFunction();
    const project_companies = await findCompaniesByProjectIdFunc(project_id);
    dispatch(
      getCompaniesSuccess(
        [
          ...new Map(_companies.map((item) => [item.id, item])).values(),
        ].filter((c) => project_companies.some((p) => p.company_id == c.id)),
      ),
    );
  } catch (error) {
    dispatch(asyncFailure('getCompanies'));
  }
};

export const addCompany = (companyObj) => async (dispatch) => {
  dispatch(asyncStart('addCompany'));
  try {
    const company = await addCompanyFunction(companyObj);
    dispatch(addCompanySuccess(company));
    return company;
  } catch (error) {
    dispatch(asyncFailure('addCompany'));
  }
};

export const updateCompany = (companyObj) => async (dispatch) => {
  dispatch(asyncStart('updateCompany'));
  try {
    await updateCompanyFunction(companyObj);
    dispatch(updateCompanySuccess(companyObj));
  } catch (error) {
    dispatch(asyncFailure('updateCompany'));
  }
};

export const deleteCompany = (company_id) => async (dispatch) => {
  dispatch(asyncStart('deleteCompany'));
  try {
    await deleteCompanyFunction(company_id);
    dispatch(deleteCompanySuccess(company_id));
  } catch (error) {
    dispatch(asyncFailure('deleteCompany'));
  }
};

export const addProjectToCompany = (company_id, project_id) => async (dispatch) => {
  dispatch(asyncStart('addProject'));
  try {
    await addProjectToCompanyFunction(company_id, project_id);
    dispatch(addProjectToCompanySuccess(company_id, project_id));
  } catch (error) {
  }
};

export const deleteProjectToCompany = (company_id, project_id) => async (dispatch) => {
  try {
    await deleteProjectToCompanyFunction(company_id, project_id);
    dispatch(deleteProjectToCompanySuccess(company_id, project_id));
  } catch (error) {
  }
};

export const updateCompanyLogo = (companyObj) => async (dispatch) => {
  dispatch(asyncStart('updateCompanyLogo'));
  try {
    await updateCompanyLogoFunction(companyObj);
    dispatch(updateCompanyLogoSuccess(companyObj));
  } catch (error) {
  }
};

export const findCompanyById = (company_id, setCompany) => async (dispatch, state) => {
  // dispatch(asyncStart('findCompanyId'));
  const { companies } = state;
  if (companies) {
    const _company = companies.filter((company) => company.id === company_id);
    if (_company.length) { return _company[0]; }
  }

  try {
    const response = await findByIdCompaniessFunction(company_id);
    if (setCompany) { dispatch(getCompaniesSuccess(response)); }
    return response;
  } catch (error) {
    // dispatch(asyncFailure('findCompanyId'));
    return null;
  }
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocaleFunction, updateNotificationFunction } from 'api';

import { i18nSelector, setLanguage } from '../../slices';

import { authentication, changeValues } from '../../helpers/authentication';

import SelectMenu from '../Menu/SelectMenu';

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid #555;
`;

const InfoTıtle = styled.div`
  padding: 10px;
  font-size: 20px;
  margin-left: 15px;
  font-weight: bold;
  color: #797586;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  margin-left: 25px;
  color: #797586 !important;
  text-align: left !important;
  padding: 20px 10px !important;
  font-weight: bold;
`;

const SectionInfo = styled.div`
  font-size: 16px;
  margin-left: 25px;
  color: #797586 !important;
  text-align: left !important;
  padding: 10px 10px 5px 10px !important;
  font-weight: bold;
`;

const SectionDetail = styled.div`
  font-size: 16px;
  margin-left: 25px;
  color: #cbc9d0 !important;
  text-align: left !important;
  padding: 5px 10px !important;
`;

const SwitchContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  paddings: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  nodata: {
    padding: '10px',
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default () => {
  const classes = useStyles();
  const intl = useIntl();

  const { locale } = useSelector(i18nSelector);

  const dispatch = useDispatch();

  const {
    id: userId,
    is2fEnabled,
    token,
    notification_email: notificationEmail,
    notification_push: notificationPush,
    instant_daily: instantDaily,
  } = authentication.userValue;

  const [allValues, setAllValues] = useState({
    notificationEmail,
    notificationPush,
    instantDaily,
  });

  const handle2FControl = () => {
    if (is2fEnabled) {
      authentication.disable2F();
    } else if (token) {
      // history.push('verify', { token, activate2f: true });
    }
  };

  return (
    <>
      <InfoHeader>
        <InfoTıtle>
          <FormattedMessage id="ACCOUNT_SETTINGS" />
        </InfoTıtle>
      </InfoHeader>
      <Grid container>
        <Grid container>
          <Grid item xs>
            <SectionTitle>
              <FormattedMessage id="MAIL_NOTIFICATION_SETTINGS" />
            </SectionTitle>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="ENABLE_MAIL_NOTIFICATION" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="ENABLE_MAIL_NOTIFICATION_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <AntSwitch
                checked={allValues.notificationEmail}
                onChange={async () => {
                  const tempValues = {
                    ...allValues,
                    notificationEmail: !allValues.notificationEmail ? 1 : 0,
                  };
                  const result = await updateNotificationFunction(
                    userId,
                    tempValues.notificationEmail,
                    tempValues.notificationPush,
                    tempValues.instantDaily,
                  );
                  setAllValues(tempValues);

                  changeValues({
                    notification_email: tempValues.notificationEmail,
                  });
                }}
                name="notifyEmail"
              />
            </SwitchContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="CHOICE_MAIL_NOTIFICATION" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="CHOICE_MAIL_NOTIFICATION_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <Paper className={clsx(classes.paper, classes.paddings)}>
                <SelectMenu
                  label=""
                  selectedValue={
                    instantDaily
                      ? { id: 1, name: intl.formatMessage({ id: 'DAILY' }) }
                      : { id: 0, name: intl.formatMessage({ id: 'INSTANT' }) }
                  }
                  list={[
                    { id: 0, name: intl.formatMessage({ id: 'INSTANT' }) },
                    { id: 1, name: intl.formatMessage({ id: 'DAILY' }) },
                  ]}
                  valueIndicator="name"
                  onSelect={async (value) => {
                    const tempValues = { ...allValues, instantDaily: value.id };
                    await updateNotificationFunction(
                      userId,
                      tempValues.notificationEmail,
                      tempValues.notificationPush,
                      tempValues.instantDaily,
                    );
                    setAllValues(tempValues);

                    changeValues({ instant_daily: value.id });
                  }}
                />
              </Paper>
            </SwitchContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <SectionTitle>
              <FormattedMessage id="LANGUAGE_SETTINGS" />
            </SectionTitle>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="LANGUAGE_PREFERENCE" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="LANGUAGE_PREFERENCE_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <Paper className={clsx(classes.paper, classes.paddings)}>
                <SelectMenu
                  label=""
                  selectedValue={
                    locale === 'tr'
                      ? { id: 1, name: 'Türkçe', code: 'tr' }
                      : { id: 2, name: 'English', code: 'en' }
                  }
                  list={[
                    { id: 1, name: 'Türkçe', code: 'tr' },
                    { id: 2, name: 'English', code: 'en' },
                  ]}
                  valueIndicator="name"
                  onSelect={(value) => {
                    dispatch(setLanguage(value.code));
                    updateLocaleFunction(userId, value.code);
                    localStorage.setItem('locale', value.code);
                  }}
                />
              </Paper>
            </SwitchContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <SectionTitle>
              <FormattedMessage id="2F_AUTH_SETTINGS" />
            </SectionTitle>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="ENABLE_2F_AUTH" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="ENABLE_2F_AUTH_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <AntSwitch
                checked={is2fEnabled}
                onChange={handle2FControl}
                name="checked"
              />
            </SwitchContainer>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="ENABLE_INPUT_NOTIFICATION" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="ENABLE_INPUT_NOTIFICATION_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <AntSwitch checked={checked} onChange={handleChange} name="checked" />
            </SwitchContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="ENABLE_TASK_NOTIFICATION" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="ENABLE_TASK_NOTIFICATION_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <AntSwitch checked={checked} onChange={handleChange} name="checked" />
            </SwitchContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs direction="column">
            <Grid item xs>
              <SectionInfo>
                <FormattedMessage id="ENABLE_PROJECT_NOTIFICATION" />
              </SectionInfo>
            </Grid>
            <Grid item xs>
              <SectionDetail>
                <FormattedMessage id="ENABLE_PROJECT_NOTIFICATION_DETAIL" />
              </SectionDetail>
            </Grid>
          </Grid>
          <Grid item>
            <SwitchContainer>
              <AntSwitch checked={checked} onChange={handleChange} name="checked" />
            </SwitchContainer>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

import React, { forwardRef, useEffect, useState } from 'react';
import { ContentContainer } from 'styles/common';
import { authentication } from 'helpers/authentication';
import { useSelector } from 'react-redux';
import JSEncrypt from 'jsencrypt';

import { projectsSelector } from '../../slices';

export default forwardRef((props, ref) => {
  const [url, setUrl] = useState('');
  const { selectedProject } = useSelector(projectsSelector);
  const { id: user_id, token } = authentication.userValue;

  useEffect(() => {
    if (user_id && token && selectedProject) {
      const rsa_public_key = `-----BEGIN ${process.env.REACT_APP_RSA_KEY_FORMAT} KEY----- ${process.env.REACT_APP_RSA_PUBLIC_KEY} -----END ${process.env.REACT_APP_RSA_KEY_FORMAT} KEY-----`;

      function encryptMessage(message, publicKey) {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        return jsEncrypt.encrypt(message);
      }
      const e_key = encryptMessage(token, rsa_public_key);
      const encrypted_token = encodeURIComponent(e_key);

      const _url = `https://report.crane.ist/report?project_id=${selectedProject.id}&token=${encrypted_token}&user_id=${user_id}`;
      setUrl(_url);
    }
  }, [user_id, token, selectedProject]);

  return (
    <>
      {/*
      <ContentContainer style={{ height: '80vh', flexDirection: 'row' }}>
        <iframe
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGE5YjllZTQtZmRiMS00NjU2LTg3ZWYtZTJiNzhjNzc0OGVjIiwidCI6ImZmZGMwODA0LWRkMzgtNGY1Zi04N2JjLTc3ZTRiM2NmZGZiMCIsImMiOjl9&pageName=ReportSection"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </ContentContainer>
      */}
      {!!selectedProject && !!url && (
        <div style={{ height: '135vh' }}>
          <iframe
            width="100%"
            height="100%"
            src={url}
            frameBorder="0"
            allowFullScreen
            scrolling="yes"
          />
        </div>
      )}
    </>
  );
});
